export const validate = (values) => {
  const errors = {};
  if (!values.associado) {
    errors.associado = 'O campo Associado não pode estar vazio';
  }
  if (!values.servico) {
    errors.servico = 'O campo Serviço não pode estar vazio';
  }
  if (!values.vencimento) {
    errors.vencimento = 'O campo Vencimento não pode estar vazio';
  }
  return errors;
};
