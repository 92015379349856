import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Button,
  Card,
  CardBody,
  Col,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import { Link, useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import Modal from '@/shared/components/Modal';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import {
  deleteChapa,
  fetchSearchChapas,
} from '../../../redux/actions/chapaActions';

const ChapasList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const chapas = useSelector((state) => state.chapas.chapas);
  const totalPages = useSelector((state) => state.chapas.totalPages);
  const totalItems = useSelector((state) => state.chapas.totalItems);
  const isFetching = useSelector((state) => state.chapas.isFetching);
  const isUpdating = useSelector((state) => state.chapas.isUpdating);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});

  const colunas = useMemo(
    () => [
      {
        Header: 'Número',
        accessor: 'numero',
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'Nome',
        accessor: 'nome',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Cor',
        accessor: 'cor',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Ação',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 80,
      },
    ],
    []
  );

  const dadosTabela = (chapas) => {
    return chapas.map((chapa) => ({
      numero: chapa.numero,
      nome: chapa.nome,
      cor: (
        <div
          className="color-picker__color-view"
          style={{ backgroundColor: chapa.cor?.hex }}
        />
      ),
      acao: (
        <div className="list__icon-btn-group">
          <button
            className="icon-btn success"
            id="editar"
            type="button"
            onClick={() => history.push(`/chapa/editar/${chapa._id}`)}
          >
            <EditIcon />
          </button>
          <UncontrolledTooltip placement="left" target="editar">
            Editar
          </UncontrolledTooltip>
          <Modal
            color="danger"
            title="Excluir Chapa"
            accept="Excluir"
            dismiss="Cancelar"
            btn={
              <button className="icon-btn danger" type="button" id="excluir">
                <TrashIcon />
              </button>
            }
            onAccept={() => {
              dispatch(deleteChapa(chapa._id));
              setPage(1);
            }}
          >
            <span>
              Você realmente deseja excluir <br /> a chapa&nbsp;
              <b>{chapa.numero}</b> permanentemente?
            </span>
          </Modal>
          <UncontrolledTooltip placement="left" target="excluir">
            Excluir
          </UncontrolledTooltip>
        </div>
      ),
    }));
  };

  // fetch eventos when page load
  useEffect(() => {
    dispatch(fetchSearchChapas(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    searchForm.filters = {
      status: data.statusSelect?.value,
    };
    setSearch(searchForm);
  };

  return (
    <Col md={9} lg={9} xl={9}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Chapas cadastradas</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link className="btn btn-primary list__btn-add" to="/chapa/novo">
                <PlusIcon /> Nova chapa
              </Link>
            </ButtonToolbar>
          </div>
          <div className="search__form">
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          component={FormField}
                          placeholder="Pesquisa..."
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={8} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      <SearchIcon /> Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {(isFetching || isUpdating) && (
            <Spinner className="table-fetch-spinner" />
          )}
          {chapas && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(chapas)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ChapasList;
