import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import { Field, Form, FormSpy } from 'react-final-form';

import FormField, {
  renderComponentField,
} from '@/shared/components/form/FormField';
import SelectField from '@/shared/components/form/Select';
import renderDropZoneField from '@/shared/components/form/DropZone';
import {
  createNoticia,
  getNoticia,
  newNoticiaRequest,
  updateNoticia,
} from '../../../redux/actions/noticiaActions';
import { listaSituacaoNoticia, arrayToBase64 } from '../../../utils/helpers';
import { validate } from './validate';

import TrashIcon from 'mdi-react/TrashIcon';

const TextArea = renderComponentField('textarea');
export const NoticiaForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const [removerImagem, setRemoverImagem] = useState(false);
  const noticia = useSelector((state) => state.noticias.noticia);
  const isFetching = useSelector((state) => state.noticias.isFetching);
  const isUpdating = useSelector((state) => state.noticias.isUpdating);
  let error = useSelector((state) => state.noticias.error);
  const { id } = props.match.params;
  const isAddMode = !id;
  const formRef = useRef({});

  const img = useMemo(
    () =>
      noticia?.imagem?.data?.data
        ? arrayToBase64(noticia?.imagem?.data?.data)
        : null,
    [noticia]
  );

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getNoticia(id));
    } else {
      dispatch(newNoticiaRequest());
    }
  }, [dispatch, id, isAddMode]);

  useEffect(() => {
    if (removerImagem) {
      noticia.imagem = [];
      setRemoverImagem(false);
    }
  }, [removerImagem, noticia]);

  const onSubmit = (data) => {
    const formData = new FormData();
    const fields = ['titulo', 'descricao', 'situacao'];
    fields.forEach((item) => {
      formData.set(item, data[item]);
    });
    if (data.files && data.files.length > 0) {
      formData.append('file', data.files[0]);
    }

    formData.set('situacao', data.situacao.value);
    isAddMode
      ? dispatch(createNoticia(formData))
      : dispatch(updateNoticia(id, formData));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/noticias" />;
  }

  if (noticia) {
    noticia.situacao = listaSituacaoNoticia.find(
      (item) => item.value === noticia.situacao
    );
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">{isAddMode ? 'Nova' : 'Editar'} Notícia</h4>
        </div>
        {(isFetching || isUpdating) && (
          <Spinner className="table-fetch-spinner" />
        )}
        {(noticia || isAddMode) && !isFetching && !isUpdating && (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : noticia}
          >
            {({ handleSubmit, form, form: {} }) => {
              formRef.current = form;
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => {}}
                  />
                  <Col lg={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Titulo</span>
                      <div className="form__form-group-field">
                        <Field
                          name="titulo"
                          component={FormField}
                          type="string"
                          rules={{ required: 'Campo Obrigatório' }}
                          isAboveError={isAboveError}
                          placeholder="Digite o titulo"
                          maxLength="100"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Situação</span>
                      <div className="form__form-group-field">
                        <Field
                          name="situacao"
                          type="text"
                          component={SelectField}
                          className="form-control mb-2 registration-input-box"
                          rules={{ required: true }}
                          isAboveError={isAboveError}
                          placeholder="Selecione uma opção"
                          options={listaSituacaoNoticia}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Descrição</span>
                      <div className="form__form-group-field">
                        <Field
                          name="descricao"
                          type="text"
                          component={TextArea}
                          isAboveError={isAboveError}
                          placeholder="Descrição"
                          maxlength="512"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Imagem de Capa da Notícia
                      </span>
                      {(!img || noticia?.imagem?.data?.data === undefined) && (
                        <Field name="files" component={renderDropZoneField} />
                      )}
                      {img && noticia?.imagem?.data?.data?.length > 0 && (
                        <div className="noticia__imagem_container">
                          <div className="noticia__imagem_botao">
                            <Button
                              color="danger"
                              className="btn-sm"
                              onClick={(e) => {
                                setRemoverImagem(true);
                              }}
                            >
                              <TrashIcon /> Remover
                            </Button>
                          </div>
                          <img
                            className="noticia__imagem"
                            src={img}
                            alt="Imagem da Notícia"
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* <Col md={8}>
                    <div className="d-flex justify-content-center">
                      <img src={noticia.imagem} />
                    </div>
                  </Col> */}
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setIsRedirect(true);
                        error = !error;
                      }}
                    >
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
