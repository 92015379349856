import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { VouchersList } from './components/VouchersList';

const Vouchers = ({ rtl }) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Vouchers</h3>
          <h3 className="page-subhead subhead">
            Crie ou atualize o cadastro de vouchers na plataforma.
          </h3>
        </Col>
      </Row>
      <Row>
        <VouchersList />
      </Row>
    </Container>
  );
};

export default Vouchers;
