export const validate = (values) => {
  const errors = [{ dependente: [] }];
  let temErro = false;

  for (let i in values.dependente) {
    const error = {};
    if (!values.dependente[i].nome) {
      error.nome = 'O campo Nome não pode estar vazio';
      temErro = true;
    }
    if (!values.dependente[i].sexo) {
      error.sexo = 'O campo Sexo não pode estar vazio';
      temErro = true;
    }
    if (!values.dependente[i].dataNascimento) {
      error.dataNascimento = 'O campo Data de Nascimento não pode estar vazio';
      temErro = true;
    }
    if (!values.dependente[i].cpf) {
      error.cpf = 'O campo CPF não pode estar vazio';
      temErro = true;
    }
    errors.push(error);
  }
  if (temErro === true) {
    return [{}];
  } else if (temErro === false) {
    return errors;
  }
};
