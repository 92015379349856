import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { Field, Form, FormSpy } from 'react-final-form';
import MaskedField from '@/shared/components/form/MaskedField';
import FormField from '@/shared/components/form/FormField';
import SelectField from '@/shared/components/form/Select';
import SearchIcon from 'mdi-react/SearchIcon';
import rendeRadioField from '@/shared/components/form/RadioButton';
import { getCEP, newCep } from '@/redux/actions/cepActions';
import {
  regexFiltarNaN,
  formatFields,
  listaEmpresas,
  listaEstadoCivil,
  telefoneMask,
  maskCEP,
  formatarCpf,
} from '@/utils/helpers';
import { validate } from './validate';

export const DadosPessoais = ({
  isHorizontal,
  isAboveError,
  props,
  handleSubmit,
  handleIsRedirect,
}) => {
  const dispatch = useDispatch();
  const associado = useSelector((state) => state.associados.associado || {});
  const isFetching = useSelector((state) => state.associados.isFetching);
  const isUpdating = useSelector((state) => state.associados.isUpdating);
  const cep = useSelector((state) => state.cep.cep);
  const cepIsFetching = useSelector((state) => state.cep.isFetching);
  const { id } = props.match.params;
  const isAddMode = !id;
  const formRef = useRef({});

  useEffect(() => () => dispatch(newCep), [dispatch]);

  useEffect(() => {
    if (Object.keys(cep).length === 0 || cep?.erro) {
      return;
    }
    let delivery_address = `${cep.logradouro}${
      cep.complemento ? ` ${cep.complemento}` : ''
    }, ${cep.bairro}`;
    delivery_address += `, ${cep.localidade} - ${cep.uf}`;
    formRef?.current.change('CEP', cep.cep);
    formRef?.current.change('endereco', delivery_address);
  }, [cep]);

  const submitCep = () => {
    const formCep = formRef.current.getState().values.cep;
    if (!formCep) {
      return;
    }
    let value = formCep.replace(regexFiltarNaN, '');
    dispatch(getCEP(value));
  };

  if (associado) {
    associado.estadoCivil = listaEstadoCivil.find(
      (item) => item.value === associado.estadoCivil
    );
    associado.perfil = listaEmpresas.find(
      (item) => item.value === associado.perfil
    );
    associado.empresaVinculo = listaEmpresas.find(
      (item) => item.value === associado.empresaVinculo
    );
    associado.dataNascimento = moment(associado.dataNascimento).format(
      'yyyy-MM-DD'
    );
  }

  return (
    <>
      {(isFetching || isUpdating) && (
        <Spinner className="table-fetch-spinner" />
      )}
      {(associado || isAddMode) && !isFetching && !isUpdating && (
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={isAddMode ? {} : associado}
        >
          {({ handleSubmit, form }) => {
            formRef.current = form;
            return (
              <form
                className={`form ${isHorizontal && 'form--horizontal'}`}
                onSubmit={handleSubmit}
              >
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values }) => {
                    const fields = [
                      { name: 'telCelular', mask: telefoneMask },
                      { name: 'telComercial', mask: telefoneMask },
                      { name: 'cpf', mask: formatarCpf },
                    ];
                    formatFields(fields, formRef);
                  }}
                />
                <Container>
                  <Row>
                    <Col xl={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Nome</span>
                        <div className="form__form-group-field">
                          <Field
                            name="nome"
                            component={FormField}
                            type="string"
                            rules={{ required: 'Campo Obrigatório' }}
                            isAboveError={isAboveError}
                            placeholder="Digite o nome"
                            maxLength="100"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">E-mail</span>
                        <div className="form__form-group-field">
                          <Field
                            name="email"
                            type="email"
                            component={FormField}
                            rules={{ required: false }}
                            isAboveError={isAboveError}
                            placeholder="Digite o e-mail"
                            maxLength="70"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">CPF</span>
                        <div className="form__form-group-field">
                          <Field
                            name="cpf"
                            component={FormField}
                            type="string"
                            rules={{ required: 'Campo Obrigatório' }}
                            isAboveError={isAboveError}
                            placeholder="Digite o seu CPF"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">RG</span>
                        <div className="form__form-group-field">
                          <Field
                            name="rg"
                            component={FormField}
                            type="number"
                            rules={{ required: 'Campo Obrigatório' }}
                            isAboveError={isAboveError}
                            placeholder="Digite o RG"
                            maxLength="10"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Data de Nascimento
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="dataNascimento"
                            type="date"
                            placeholder="dd/mm/aaaa – hh:mm"
                            component={FormField}
                            rules={{ required: false }}
                            isAboveError={isAboveError}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Sexo</span>
                        <div className="form__form-group-field">
                          <Field
                            name="sexo"
                            component={rendeRadioField}
                            label="Masculino"
                            radioValue="masculino"
                            className="mb-0 mt-2"
                            isAboveError={isAboveError}
                          />
                          <Field
                            name="sexo"
                            component={rendeRadioField}
                            label="Feminino"
                            radioValue="feminino"
                            className="mb-0 mt-2"
                            isAboveError={isAboveError}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Estado Civil
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="estadoCivil"
                            type="text"
                            component={SelectField}
                            className="form-control mb-2 registration-input-box"
                            rules={{ required: true }}
                            isAboveError={isAboveError}
                            placeholder="Selecione um campo"
                            options={listaEstadoCivil}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Matrícula
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="matricula"
                            type="string"
                            component={FormField}
                            rules={{ required: false }}
                            isAboveError={isAboveError}
                            placeholder="Digite a matrícula"
                            maxLength="10"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Empresa na qual trabalha
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="empresaVinculo"
                            type="text"
                            component={SelectField}
                            className="form-control mb-2 registration-input-box"
                            rules={{ required: true }}
                            isAboveError={isAboveError}
                            placeholder="Selecione um campo"
                            options={listaEmpresas}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Telefone Comercial
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="telComercial"
                            type="string"
                            component={FormField}
                            rules={{ required: 'Campo Obrigatório' }}
                            isAboveError={isAboveError}
                            placeholder="(00) 00000-0000"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Telefone Celular
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="telCelular"
                            type="string"
                            component={FormField}
                            rules={{ required: 'Campo Obrigatório' }}
                            isAboveError={isAboveError}
                            placeholder="(00) 00000-0000"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={2}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">CEP</span>
                        <div className="form__form-group-field">
                          <Field
                            name="cep"
                            type="text"
                            mask={maskCEP}
                            component={MaskedField}
                            isAboveError={isAboveError}
                            placeholder="00000-000"
                          />
                          <button
                            className="search-btn form__form-group-button"
                            type="button"
                            disabled={cepIsFetching}
                            onClick={submitCep}
                          >
                            <SearchIcon />
                          </button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={10}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Endereço completo (logradouro, número e complemento)
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="endereco"
                            type="text"
                            component={FormField}
                            rules={{ required: false }}
                            isAboveError={isAboveError}
                            placeholder="Digite o endereço completo"
                            maxLength="50"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xl={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Nome do Pai
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="nomePai"
                            component={FormField}
                            type="string"
                            rules={{ required: 'Campo Obrigatório' }}
                            isAboveError={isAboveError}
                            placeholder="Digite o nome do pai"
                            maxLength="100"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Nome da Mãe
                        </span>
                        <div className="form__form-group-field">
                          <Field
                            name="nomeMae"
                            component={FormField}
                            type="string"
                            rules={{ required: 'Campo Obrigatório' }}
                            isAboveError={isAboveError}
                            placeholder="Digite o nome da mãe"
                            maxLength="100"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        handleIsRedirect(true);
                      }}
                    >
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </Container>
              </form>
            );
          }}
        </Form>
      )}
    </>
  );
};
