import { handleActions } from 'redux-actions';

import {
  getServicoRequest,
  getServicoSuccess,
  getServicoError,
  fetchServicosRequest,
  fetchServicosSuccess,
  fetchServicosError,
  fetchSearchServicosRequest,
  fetchSearchServicosSuccess,
  fetchSearchServicosError,
  createServicoRequest,
  createServicoSuccess,
  createServicoError,
  updateServicoRequest,
  updateServicoSuccess,
  updateServicoError,
  deleteServicoRequest,
  deleteServicoSuccess,
  deleteServicoError,
  newServicoRequest,
} from '../actions/servicoActions';

const defaultState = {
  servicos: [],
  totalPages: 0,
  servico: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getServicoRequest](state) {
      return {
        ...state,
        servico: {},
        isFetching: true,
        error: null,
      };
    },
    [getServicoSuccess](state, { payload }) {
      return {
        ...state,
        servico: payload,
        isFetching: false,
        error: null,
      };
    },
    [getServicoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchServicosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchServicosSuccess](state, { payload }) {
      return {
        ...state,
        servicos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchServicosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchServicosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchServicosSuccess](state, { payload }) {
      return {
        ...state,
        servicos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchServicosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createServicoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createServicoSuccess](state, { payload }) {
      return {
        ...state,
        servico: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createServicoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateServicoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateServicoSuccess](state, { payload }) {
      return {
        ...state,
        servico: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateServicoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteServicoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteServicoSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteServicoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newServicoRequest](state, { payload }) {
      return {
        ...state,
        servico: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
  },
  defaultState
);
