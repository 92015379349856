import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../Account/LogIn/index';
import ChangePassword from '../Account/ChangePassword/index';
import Dashboard from '../Dashboard/index';
import ResetPassword from '../Account/ResetPassword/index';
import Usuarios from '../Usuarios';
import Usuario from '../Usuario';
import Associados from '../Associados';
import Associado from '../Associado';
import Chapas from '../Chapas';
import Chapa from '../Chapa';
import Assembleias from '../Assembleias';
import Assembleia from '../Assembleia';
import Resultado from '../Resultado';
import Servicos from '../Servicos';
import Servico from '../Servico';
import Vouchers from '../Vouchers';
import Voucher from '../Voucher';
import Noticias from '../Noticias';
import Noticia from '../Noticia';
import Banners from '../Banners';
import Banner from '../Banner';
import Mensagens from '../Mensagens';
import Mensagem from '../Mensagem';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/administradores" component={Usuarios} />
      <PrivateRoute path="/usuario/novo" component={Usuario} />
      <PrivateRoute path="/usuario/editar/:id" component={Usuario} />
      <PrivateRoute path="/associados" component={Associados} />
      <PrivateRoute path="/associado/novo" component={Associado} />
      <PrivateRoute path="/associado/editar/:id" component={Associado} />
      <PrivateRoute path="/chapas" component={Chapas} />
      <PrivateRoute path="/chapa/novo" component={Chapa} />
      <PrivateRoute path="/chapa/editar/:id" component={Chapa} />
      <PrivateRoute path="/assembleias" component={Assembleias} />
      <PrivateRoute path="/assembleia/novo" component={Assembleia} />
      <PrivateRoute path="/assembleia/editar/:id" component={Assembleia} />
      <PrivateRoute path="/assembleia/resultado/:id" component={Resultado} />
      <PrivateRoute path="/servicos" component={Servicos} />
      <PrivateRoute path="/servico/novo" component={Servico} />
      <PrivateRoute path="/servico/editar/:id" component={Servico} />
      <PrivateRoute path="/vouchers" component={Vouchers} />
      <PrivateRoute path="/voucher/novo" component={Voucher} />
      <PrivateRoute path="/voucher/ver/:id" component={Voucher} />
      <PrivateRoute path="/noticias" component={Noticias} />
      <PrivateRoute path="/noticia/novo" component={Noticia} />
      <PrivateRoute path="/noticia/editar/:id" component={Noticia} />
      <PrivateRoute path="/banners" component={Banners} />
      <PrivateRoute path="/banner/novo" component={Banner} />
      <PrivateRoute path="/banner/editar/:id" component={Banner} />
      <PrivateRoute path="/mensagens" component={Mensagens} />
      <PrivateRoute path="/mensagem/novo" component={Mensagem} />
      <PrivateRoute path="/mensagem/ver/:id" component={Mensagem} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/recuperar-senha" component={ResetPassword} />
        <Route
          exact
          path="/trocar-senha/:reset_password_link"
          component={ChangePassword}
        />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
