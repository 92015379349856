import { handleActions } from 'redux-actions';

import {
  getAssociadoRequest,
  getAssociadoSuccess,
  getAssociadoError,
  fetchAssociadosRequest,
  fetchAssociadosSuccess,
  fetchAssociadosError,
  fetchSearchAssociadosRequest,
  fetchSearchAssociadosSuccess,
  fetchSearchAssociadosError,
  createAssociadoRequest,
  createAssociadoSuccess,
  createAssociadoError,
  updateAssociadoRequest,
  updateAssociadoSuccess,
  updateAssociadoError,
  deleteAssociadoRequest,
  deleteAssociadoSuccess,
  deleteAssociadoError,
  newAssociadoRequest,
  getStatsAssociadosRequest,
  getStatsAssociadosSuccess,
  getStatsAssociadosError,
} from '../actions/associadoActions';

const defaultState = {
  associados: [],
  totalPages: 0,
  associado: {},
  stats: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getAssociadoRequest](state) {
      return {
        ...state,
        associado: undefined,
        isFetching: true,
        error: null,
      };
    },
    [getAssociadoSuccess](state, { payload }) {
      return {
        ...state,
        associado: payload,
        isFetching: false,
        error: null,
      };
    },
    [getAssociadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchAssociadosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchAssociadosSuccess](state, { payload }) {
      return {
        ...state,
        associados: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchAssociadosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchAssociadosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchAssociadosSuccess](state, { payload }) {
      return {
        ...state,
        associados: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchAssociadosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createAssociadoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createAssociadoSuccess](state, { payload }) {
      return {
        ...state,
        associado: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createAssociadoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateAssociadoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateAssociadoSuccess](state, { payload }) {
      return {
        ...state,
        associado: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateAssociadoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteAssociadoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteAssociadoSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteAssociadoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newAssociadoRequest](state, { payload }) {
      return {
        ...state,
        associado: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
    [getStatsAssociadosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getStatsAssociadosSuccess](state, { payload }) {
      return {
        ...state,
        stats: payload,
        isFetching: false,
        error: null,
      };
    },
    [getStatsAssociadosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
