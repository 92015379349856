import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import servicoApi from '../../utils/api/servicoApi';

export const getServicoRequest = createAction('GET_SERVICO_REQUEST');
export const getServicoSuccess = createAction('GET_SERVICO_SUCCESS');
export const getServicoError = createAction('GET_SERVICO_ERROR');

export const fetchServicosRequest = createAction('FETCH_SERVICOS_REQUEST');
export const fetchServicosSuccess = createAction('FETCH_SERVICOS_SUCCESS');
export const fetchServicosError = createAction('FETCH_SERVICOS_ERROR');

export const createServicoRequest = createAction('CREATE_SERVICO_REQUEST');
export const createServicoSuccess = createAction('CREATE_SERVICO_SUCCESS');
export const createServicoError = createAction('CREATE_SERVICO_ERROR');

export const updateServicoRequest = createAction('UPDATE_SERVICO_REQUEST');
export const updateServicoSuccess = createAction('UPDATE_SERVICO_SUCCESS');
export const updateServicoError = createAction('UPDATE_SERVICO_ERROR');

export const deleteServicoRequest = createAction('DELETE_SERVICO_REQUEST');
export const deleteServicoSuccess = createAction('DELETE_SERVICO_SUCCESS');
export const deleteServicoError = createAction('DELETE_SERVICO_ERROR');

export const fetchSearchServicosRequest = createAction(
  'FETCH_SEARCH_SERVICOS_REQUEST'
);
export const fetchSearchServicosSuccess = createAction(
  'FETCH_SEARCH_SERVICOS_SUCCESS'
);
export const fetchSearchServicosError = createAction(
  'FETCH_SEARCH_SERVICOS_ERROR'
);

export const newServicoRequest = createAction('NEW_SERVICO_REQUEST');

export const getServico = (id) => async (dispatch) => {
  try {
    dispatch(getServicoRequest());
    const { data } = await servicoApi.getServico(id);
    dispatch(getServicoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getServicoError(e));
    dispatch(displayError(e));
  }
};

export const fetchServicos = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchServicosRequest());
    const { data } = await servicoApi.fetchServicos(page, pageSize);
    dispatch(fetchServicosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchServicosError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchServicos =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchServicosRequest());
      const { data } = await servicoApi.searchServicos(page, pageSize, search);
      dispatch(fetchSearchServicosSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchServicosError(e));
      dispatch(displayError(e));
    }
  };

export const createServico = (form) => async (dispatch) => {
  try {
    dispatch(createServicoRequest());
    const { data } = await servicoApi.createServico(form);
    dispatch(displaySuccess('Serviço incluído com sucesso!'));
    dispatch(createServicoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createServicoError(e));
    dispatch(displayError(e));
  }
};

export const updateServico = (id, form) => async (dispatch) => {
  try {
    dispatch(updateServicoRequest());
    const { data } = await servicoApi.updateServico(id, form);
    dispatch(displaySuccess('Serviço atualizado com sucesso'));
    dispatch(updateServicoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateServicoError(e));
    dispatch(displayError(e));
  }
};

export const deleteServico = (id) => async (dispatch) => {
  try {
    dispatch(deleteServicoRequest());
    const { data } = await servicoApi.deleteServico(id);
    dispatch(deleteServicoSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteServicoError(e));
    dispatch(displayError(e));
  }
};

export const newServico = () => async (dispatch) => {
  dispatch(newServicoRequest());
};
