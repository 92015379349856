import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { getVoucher } from '../../../redux/actions/voucherActions';
import moment from 'moment';
import { BadgeSituacao } from '../../../shared/components/Badges';
import { Field, Form } from 'react-final-form';
import FormField from '../../../shared/components/form/FormField';

const VoucherVer = ({ voucherId }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const voucher = useSelector((state) => state.vouchers.voucher);
  const isFetching = useSelector((state) => state.vouchers.isFetching);

  useEffect(() => {
    dispatch(getVoucher(voucherId));
  }, [dispatch, voucherId]);

  const handleVoltar = () => {
    setIsRedirect(true);
  };

  if (isRedirect) {
    return <Redirect to="/vouchers" />;
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">Ver Detalhes</h4>
        </div>
        {isFetching && <Spinner className="table-fetch-spinner" />}
        {voucher && !isFetching && (
          <Container>
            <Row>
              <Col md={8}>
                <div className="form">
                  <Container className="p-0">
                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Associado
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="associado"
                              value={voucher.associado?.nome}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Serviço
                          </span>
                          <div className="form__form-group-field">
                            <FormField
                              name="servico"
                              value={voucher.servico?.nome}
                              disabled
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="border p-2">
                          <table>
                            <tbody>
                              <tr>
                                <th className="pb-2">Situação:</th>
                                <td className="pb-2 pl-2">
                                  <BadgeSituacao status={voucher?.situacao} />
                                </td>
                              </tr>
                              <tr>
                                <th className="pb-2">Vencimento:</th>
                                <td className="pb-2 pl-2">
                                  {moment(voucher?.vencimento).format(
                                    'DD/MM/YYYY'
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th className="pb-2 d-flex align-items-start">
                                  Oferta:
                                </th>
                                <td className="pb-2 pl-2">
                                  {voucher?.servico?.oferta}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
              <Col md={4} className="d-flex align-items-end">
                <img
                  src={voucher.qrCode}
                  alt="QRcode do voucher"
                  className="img-fluid mx-auto border"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <ButtonToolbar className="form__button-toolbar">
                  <Button type="button" onClick={handleVoltar}>
                    Voltar
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Container>
        )}
      </CardBody>
    </Card>
  );
};

export default VoucherVer;
