export const validate = (values) => {
  const errors = {};
  if (!values.titulo) {
    errors.titulo = 'O campo Titulo não pode estar vazio';
  }
  if (!values.situacao) {
    errors.situacao = 'O campo Situação não pode estar vazio';
  }
  if (!values.ordem) {
    errors.ordem = 'O campo Ordem não pode estar vazio';
  }
  return errors;
};
