export const validate = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo Nome não pode estar vazio';
  }
  if (
    (values.password || values.passwordConfirm) &&
    values.password !== values.passwordConfirm
  ) {
    errors.password = 'A senha informada não confere!';
  }
  if (values.password && !values.passwordConfirm) {
    errors.passwordConfirm = 'É necessário confirmar a senha.';
  }
  if (!values.telCelular) {
    errors.telCelular = 'O campo Telefone não pode estar vazio';
  }
  return errors;
};
