import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormField, {
  renderComponentField,
} from '@/shared/components/form/FormField';
import SelectField from '@/shared/components/form/Select';
import renderSelectField from '@/shared/components/form/Select';
import { Button, ButtonToolbar, Card, CardBody, Spinner } from 'reactstrap';
import { Field, Form, FormSpy } from 'react-final-form';
import {
  createServico,
  getServico,
  newServicoRequest,
  updateServico,
} from '../../../redux/actions/servicoActions';
import {
  formatFields,
  telefoneMask,
  listaCategoria,
  regexFiltarNaN,
  listaSituacao,
} from '../../../utils/helpers';
import { validate } from './validate';

export const ServicoForm = ({ isHorizontal, isAboveError, props }) => {
  const TextArea = renderComponentField('textarea');
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const servico = useSelector((state) => state.servicos.servico);
  const isFetching = useSelector((state) => state.servicos.isFetching);
  const isUpdating = useSelector((state) => state.servicos.isUpdating);
  let error = useSelector((state) => state.servicos.error);
  const { id } = props.match.params;
  const isAddMode = !id;
  const formRef = useRef({});

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getServico(id));
    } else {
      dispatch(newServicoRequest());
    }
  }, [dispatch, id, isAddMode]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      categoria: (values.categoria = values.categoria?.value),
      situacao: (values.situacao = values.situacao?.value),
      nome: values.nome,
      endereco: values.endereco,
      telefone: values.telefone?.replace(regexFiltarNaN, ''),
      oferta: values.oferta,
    };
    isAddMode
      ? dispatch(createServico(data))
      : dispatch(updateServico(id, data));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/servicos" />;
  }

  if (servico) {
    servico.categoria = listaCategoria.find(
      (item) => item.value === servico.categoria
    );
    servico.situacao = listaSituacao.find(
      (item) => item.value === servico.situacao
    );
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">{isAddMode ? 'Novo' : 'Editar'} Serviço</h4>
        </div>
        {(isFetching || isUpdating) && (
          <Spinner className="table-fetch-spinner" />
        )}
        {(servico || isAddMode) && !isFetching && !isUpdating && (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : servico}
          >
            {({ handleSubmit, form, form: {} }) => {
              formRef.current = form;
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => {
                      const fields = [{ name: 'telefone', mask: telefoneMask }];
                      formatFields(fields, formRef);
                    }}
                  />
                  <div className="form__form-group">
                    <span className="form__form-group-label">Nome</span>
                    <div className="form__form-group-field">
                      <Field
                        name="nome"
                        component={FormField}
                        type="string"
                        rules={{ required: 'Campo Obrigatório' }}
                        isAboveError={isAboveError}
                        placeholder="Digite o Nome"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Categoria</span>
                    <div className="form__form-group-field">
                      <Field
                        name="categoria"
                        type="text"
                        component={SelectField}
                        className="form-control mb-2 registration-input-box"
                        rules={{ required: true }}
                        isAboveError={isAboveError}
                        placeholder="Selecione uma opção"
                        options={listaCategoria}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Endereço</span>
                    <div className="form__form-group-field">
                      <Field
                        name="endereco"
                        type="text"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="Endereço"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Telefone</span>
                    <div className="form__form-group-field">
                      <Field
                        name="telefone"
                        type="string"
                        component={FormField}
                        rules={{ required: 'Campo Obrigatório' }}
                        isAboveError={isAboveError}
                        placeholder="(00) 00000-0000"
                        maxLength="15"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Situação</span>
                    <div className="form__form-group-field">
                      <Field
                        name="situacao"
                        component={renderSelectField}
                        type="text"
                        options={listaSituacao}
                        placeholder="Selecione uma opção"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Oferta</span>
                    <div className="form__form-group-field">
                      <Field
                        name="oferta"
                        type="text"
                        component={TextArea}
                        isAboveError={isAboveError}
                        placeholder="Oferta"
                        maxlength="512"
                      />
                    </div>
                  </div>

                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setIsRedirect(true);
                        error = !error;
                      }}
                    >
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
