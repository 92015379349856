import { deleteRequest, get, post, put } from './base/index';
import { getUserId } from '../helpers';
import { API } from '../constants';

export default {
  getNoticia: (id) => get(`${API}/admin/noticia/${id}/${getUserId()}`),
  fetchNoticias: (page, pageSize) =>
    get(
      `${API}/admin/noticias/${getUserId()}?page=${page}&pageSize=${pageSize}`
    ),
  searchNoticias: (page, pageSize, pesquisa) =>
    post(
      `${API}/admin/noticias/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      pesquisa
    ),
  deleteNoticia: (id) =>
    deleteRequest(`${API}/admin/noticia/${id}/${getUserId()}`),
  createNoticia: (form) =>
    post(`${API}/admin/noticia/create/${getUserId()}`, form),
  updateNoticia: (id, form) =>
    put(`${API}/admin/noticia/${id}/${getUserId()}`, form),
};
