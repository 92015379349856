import { handleActions } from 'redux-actions';

import {
  getBannerRequest,
  getBannerSuccess,
  getBannerError,
  fetchBannersRequest,
  fetchBannersSuccess,
  fetchBannersError,
  fetchSearchBannersRequest,
  fetchSearchBannersSuccess,
  fetchSearchBannersError,
  createBannerRequest,
  createBannerSuccess,
  createBannerError,
  updateBannerRequest,
  updateBannerSuccess,
  updateBannerError,
  deleteBannerRequest,
  deleteBannerSuccess,
  deleteBannerError,
  newBannerRequest,
} from '../actions/bannerActions';

const defaultState = {
  banners: [],
  totalPages: 0,
  banner: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getBannerRequest](state) {
      return {
        ...state,
        banner: {},
        isFetching: true,
        error: null,
      };
    },
    [getBannerSuccess](state, { payload }) {
      return {
        ...state,
        banner: payload,
        isFetching: false,
        error: null,
      };
    },
    [getBannerError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchBannersRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchBannersSuccess](state, { payload }) {
      return {
        ...state,
        banners: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchBannersError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchBannersRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchBannersSuccess](state, { payload }) {
      return {
        ...state,
        banners: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchBannersError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createBannerRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createBannerSuccess](state, { payload }) {
      return {
        ...state,
        banner: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createBannerError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateBannerRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateBannerSuccess](state, { payload }) {
      return {
        ...state,
        banner: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateBannerError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteBannerRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteBannerSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteBannerError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newBannerRequest](state, { payload }) {
      return {
        ...state,
        banner: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
  },
  defaultState
);
