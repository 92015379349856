import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ChapasList from './components/ChapasList';

const Chapas = ({ rtl }) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Chapas</h3>
          <h3 className="page-subhead subhead">Chapas cadastradas</h3>
        </Col>
      </Row>
      <Row>
        <ChapasList />
      </Row>
    </Container>
  );
};

export default Chapas;
