import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import mensagemApi from '../../utils/api/mensagemApi';

export const getMensagemRequest = createAction('GET_MENSAGEM_REQUEST');
export const getMensagemSuccess = createAction('GET_MENSAGEM_SUCCESS');
export const getMensagemError = createAction('GET_MENSAGEM_ERROR');

export const createMensagemRequest = createAction('CREATE_MENSAGEM_REQUEST');
export const createMensagemSuccess = createAction('CREATE_MENSAGEM_SUCCESS');
export const createMensagemError = createAction('CREATE_MENSAGEM_ERROR');

export const fetchSearchMensagensRequest = createAction(
  'FETCH_SEARCH_MENSAGENS_REQUEST'
);
export const fetchSearchMensagensSuccess = createAction(
  'FETCH_SEARCH_MENSAGENS_SUCCESS'
);
export const fetchSearchMensagensError = createAction(
  'FETCH_SEARCH_MENSAGENS_ERROR'
);

export const newMensagemRequest = createAction('NEW_MENSAGEM_REQUEST');

export const getMensagem = (id) => async (dispatch) => {
  try {
    dispatch(getMensagemRequest());
    const { data } = await mensagemApi.getMensagem(id);
    dispatch(getMensagemSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getMensagemError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchMensagens =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchMensagensRequest());
      const { data } = await mensagemApi.searchMensagens(
        page,
        pageSize,
        search
      );
      dispatch(fetchSearchMensagensSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchMensagensError(e));
      dispatch(displayError(e));
    }
  };

export const createMensagem = (form) => async (dispatch) => {
  try {
    dispatch(createMensagemRequest());
    const { data } = await mensagemApi.createMensagem(form);
    dispatch(displaySuccess('Mensagem enviada com sucesso!'));
    dispatch(createMensagemSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createMensagemError(e));
    dispatch(displayError(e));
  }
};

export const newMensagem = () => async (dispatch) => {
  dispatch(newMensagemRequest());
};
