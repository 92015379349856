import { handleActions } from 'redux-actions';

import {
  getAssembleiaRequest,
  getAssembleiaSuccess,
  getAssembleiaError,
  resultadoAssembleiaRequest,
  resultadoAssembleiaSuccess,
  resultadoAssembleiaError,
  fetchAssembleiasRequest,
  fetchAssembleiasSuccess,
  fetchAssembleiasError,
  fetchSearchAssembleiasRequest,
  fetchSearchAssembleiasSuccess,
  fetchSearchAssembleiasError,
  createAssembleiaRequest,
  createAssembleiaSuccess,
  createAssembleiaError,
  updateAssembleiaRequest,
  updateAssembleiaSuccess,
  updateAssembleiaError,
  adicionarDocumentoRequest,
  adicionarDocumentoSuccess,
  adicionarDocumentoError,
  deleteDocumentoRequest,
  deleteDocumentoSuccess,
  deleteDocumentoError,
  deleteAssembleiaRequest,
  deleteAssembleiaSuccess,
  deleteAssembleiaError,
  newAssembleiaRequest,
} from '../actions/assembleiaActions';

const defaultState = {
  assembleias: [],
  totalPages: 0,
  assembleia: {},
  resultado: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getAssembleiaRequest](state) {
      return {
        ...state,
        assembleia: {},
        isFetching: true,
        error: null,
      };
    },
    [getAssembleiaSuccess](state, { payload }) {
      return {
        ...state,
        assembleia: payload,
        isFetching: false,
        error: null,
      };
    },
    [getAssembleiaError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [resultadoAssembleiaRequest](state) {
      return {
        ...state,
        resultado: {},
        isFetching: true,
        error: null,
      };
    },
    [resultadoAssembleiaSuccess](state, { payload }) {
      return {
        ...state,
        resultado: payload,
        isFetching: false,
        error: null,
      };
    },
    [resultadoAssembleiaError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchAssembleiasRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchAssembleiasSuccess](state, { payload }) {
      return {
        ...state,
        assembleias: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchAssembleiasError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchAssembleiasRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchAssembleiasSuccess](state, { payload }) {
      return {
        ...state,
        assembleias: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchAssembleiasError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createAssembleiaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createAssembleiaSuccess](state, { payload }) {
      return {
        ...state,
        assembleia: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createAssembleiaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateAssembleiaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateAssembleiaSuccess](state, { payload }) {
      return {
        ...state,
        assembleia: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateAssembleiaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [adicionarDocumentoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [adicionarDocumentoSuccess](state, { payload }) {
      return {
        ...state,
        assembleia: payload,
        isUpdating: false,
        error: null,
      };
    },
    [adicionarDocumentoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteDocumentoRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteDocumentoSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteDocumentoError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteAssembleiaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteAssembleiaSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteAssembleiaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newAssembleiaRequest](state, { payload }) {
      return {
        ...state,
        assembleia: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
  },
  defaultState
);
