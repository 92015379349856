export const validate = (values) => {
  const errors = {};
  if (!values.titulo) {
    errors.titulo = 'O campo Titulo não pode estar vazio';
  }
  if (!values.situacao) {
    errors.situacao = 'O campo Situação não pode estar vazio';
  }
  if (!values.descricao) {
    errors.descricao = 'O campo Descrição não pode estar vazio';
  }
  if (!values.situacao) {
    errors.situacao = 'O campo Situação não pode estar vazio';
  }
  if (!values.descricao) {
    errors.descricao = 'O campo Descrição não pode estar vazio';
  }
  return errors;
};
