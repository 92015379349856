import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '@/shared/components/form/FormField';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import Modal from '../../../shared/components/Modal';
import {
  ButtonToolbar,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { Link, useHistory } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import {
  fetchSearchBanners,
  deleteBanner,
} from '../../../redux/actions/bannerActions';
import { useMemo } from 'react';
import SearchIcon from 'mdi-react/SearchIcon';
import { BadgeBanner } from '../../../shared/components/Badges';

export const BannersList = () => {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.banners?.banners);
  const totalItems = useSelector((state) => state.banners?.totalItems);
  const isFetching = useSelector((state) => state.banners?.isFetching);
  const totalPages = useSelector((state) => state.banners?.totalPages);
  const isUpdating = useSelector((state) => state.banners?.isUpdating);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});
  const history = useHistory();

  const colunas = useMemo(
    () => [
      {
        Header: 'Título',
        accessor: 'titulo',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Link',
        accessor: 'link',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Ordem',
        accessor: 'ordem',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Situação',
        accessor: 'situacao',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Ação',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 80,
      },
    ],
    []
  );

  const dadosTabela = (banners) => {
    return banners.map((banner) => ({
      titulo: banner.titulo,
      link: (banner.link === 'undefined' ? '' : banner.link),
      ordem: banner.ordem,
      situacao: <BadgeBanner status={banner.situacao} />,
      acao: (
        <div className="list__icon-btn-group">
          <button
            className="icon-btn success"
            id="editar"
            type="button"
            onClick={() => history.push(`/banner/editar/${banner._id}`)}
          >
            <EditIcon />
          </button>
          <UncontrolledTooltip placement="left" target="editar">
            Editar
          </UncontrolledTooltip>
          <Modal
            color="danger"
            title="Excluir Banner"
            accept="Excluir"
            dismiss="Cancelar"
            btn={
              <button className="icon-btn danger" type="button" id="excluir">
                <TrashIcon />
              </button>
            }
            onAccept={async () => {
              await dispatch(deleteBanner(banner._id)).then(setPage(0));
            }}
          >
            <span>
              Você realmente deseja excluir <br /> o banner&nbsp;
              <b>{banner.titulo}</b> permanentemente?
            </span>
          </Modal>
          <UncontrolledTooltip placement="left" target="excluir">
            Excluir
          </UncontrolledTooltip>
        </div>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchSearchBanners(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;

    setSearch(searchForm);
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Banners cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link
                className="btn btn-primary list__btn-add"
                to={'/banner/novo'}
              >
                <PlusIcon /> Novo Banner
              </Link>
            </ButtonToolbar>
          </div>
          <div className="search_form">
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col md={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          component={FormField}
                          placeholder="Pesquisa..."
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} md={4} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      <SearchIcon /> Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {banners && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(banners)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
