import { handleActions } from 'redux-actions';

import {
  getMensagemRequest,
  getMensagemSuccess,
  getMensagemError,
  fetchSearchMensagensRequest,
  fetchSearchMensagensSuccess,
  fetchSearchMensagensError,
  createMensagemRequest,
  createMensagemSuccess,
  createMensagemError,
  newMensagemRequest,
} from '../actions/mensagemActions';

const defaultState = {
  mensagens: [],
  totalPages: 0,
  mensagem: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getMensagemRequest](state) {
      return {
        ...state,
        mensagem: {},
        isFetching: true,
        error: null,
      };
    },
    [getMensagemSuccess](state, { payload }) {
      return {
        ...state,
        mensagem: payload,
        isFetching: false,
        error: null,
      };
    },
    [getMensagemError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchMensagensRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchMensagensSuccess](state, { payload }) {
      return {
        ...state,
        mensagens: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchMensagensError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createMensagemRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createMensagemSuccess](state, { payload }) {
      return {
        ...state,
        mensagem: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createMensagemError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newMensagemRequest](state, { payload }) {
      return {
        ...state,
        mensagem: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
  },
  defaultState
);
