import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { BannerForm } from './components/BannerForm';

const Banner = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3>Banner</h3>
          <h3 className="page-subhead subhead">
            Adicione um Banner ou altere suas informações
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <BannerForm isHorizontal={false} isAboveError props={props} />
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
