import { deleteRequest, get, post, put } from './base/index';
import { getUserId } from '../helpers';
import { API } from '../constants';

export default {
  getMensagem: (id) => get(`${API}/admin/mensagem/${id}/${getUserId()}`),
  searchMensagens: (page, pageSize, pesquisa) =>
    post(
      `${API}/admin/mensagens/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      pesquisa
    ),
  createMensagem: (form) => post(`${API}/admin/mensagem/${getUserId()}`, form),
};
