import { handleActions } from 'redux-actions';

import {
  getChapaRequest,
  getChapaSuccess,
  getChapaError,
  fetchChapasRequest,
  fetchChapasSuccess,
  fetchChapasError,
  fetchSearchChapasRequest,
  fetchSearchChapasSuccess,
  fetchSearchChapasError,
  createChapaRequest,
  createChapaSuccess,
  createChapaError,
  updateChapaRequest,
  updateChapaSuccess,
  updateChapaError,
  deleteChapaRequest,
  deleteChapaSuccess,
  deleteChapaError,
  newChapaRequest,
} from '../actions/chapaActions';

const defaultState = {
  chapas: [],
  totalPages: 0,
  chapa: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getChapaRequest](state) {
      return {
        ...state,
        chapa: {},
        isFetching: true,
        error: null,
      };
    },
    [getChapaSuccess](state, { payload }) {
      return {
        ...state,
        chapa: payload,
        isFetching: false,
        error: null,
      };
    },
    [getChapaError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchChapasRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchChapasSuccess](state, { payload }) {
      return {
        ...state,
        chapas: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchChapasError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchChapasRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchChapasSuccess](state, { payload }) {
      return {
        ...state,
        chapas: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchChapasError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createChapaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createChapaSuccess](state, { payload }) {
      return {
        ...state,
        chapa: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createChapaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateChapaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateChapaSuccess](state, { payload }) {
      return {
        ...state,
        chapa: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateChapaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteChapaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteChapaSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteChapaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newChapaRequest](state, { payload }) {
      return {
        ...state,
        chapa: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
  },
  defaultState
);
