import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { VoucherForm } from './components/VoucherForm';
import VoucherVer from './components/VoucherVer';

const Voucher = (props) => {
  const { id } = props.match.params;
  const isAddMode = !id;
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3>Voucher</h3>
          <h3 className="page-subhead subhead">
            Adicione um voucher ou altere suas informações
          </h3>
        </Col>
      </Row>
      <Row>
        {(isAddMode && (
          <Col md={6}>
            <VoucherForm isHorizontal={false} isAboveError props={props} />
          </Col>
        )) || (
          <Col md={8}>
            <VoucherVer voucherId={id} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Voucher;
