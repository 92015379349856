import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Input, Modal, Row } from 'reactstrap';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import MagnifyPlusOutlineIcon from 'mdi-react/MagnifyPlusOutlineIcon';
import MagnifyMinusOutlineIcon from 'mdi-react/MagnifyMinusOutlineIcon';
import * as pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfModal extends React.Component {
  state = {
    pdf: undefined,
    loading: true,
    page: 1,
    isPageRendering: false,
    scale: 1,
    error: null,
  };

  constructor(props) {
    super(props);
    this.canvas = createRef();
  }

  async loadPdf() {
    this.setState({ loading: true });
    const { data } = this.props;
    if (!data) {
      this.setState({ loading: false, error: 'Nenhum Arquivo' });
      return;
    }
    try {
      const pdf = await pdfjs.getDocument({ data }).promise;
      this.setState({ loading: false, pdf });
    } catch (reason) {
      this.setState({ loading: false, pdf: null, maxPages: 0 });
      console.error(reason);
    }
  }

  renderPage() {
    const { page, loading, error, pdf, scale } = this.state;
    const canvas = this.canvas.current;
    const context = canvas?.getContext('2d');

    if (loading || error || !context) return;

    this.setState({ pageRendering: true });
    const me = this;

    pdf?.getPage(page).then(function (page) {
      let viewport = page.getViewport({ scale });

      // Prepare canvas using PDF page dimensions
      if (!canvas) return;
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      let renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      let renderTask = page.render(renderContext);
      renderTask.promise.then(() => {
        me.setState({ pageRendering: false });
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.open) return;
    if (prevProps.open === false) {
      /* Modal abrindo */
      this.renderPage();
      this.loadPdf();
      return;
    }
    if (this.props.data !== prevProps.data) {
      this.loadPdf();
    }
    if (
      this.state.page !== prevState.page ||
      this.state.loading !== prevState.loading ||
      this.state.scale !== prevState.scale
    ) {
      this.renderPage();
    }
  }

  handleSetPage(e, transform) {
    const { page, pdf } = this.state;
    const nextPage = +(e?.target?.value || (transform && transform(page)));

    if (
      isNaN(nextPage) ||
      nextPage === page ||
      nextPage > pdf.numPages ||
      nextPage < 1
    )
      return;

    this.setState({ page: nextPage });
  }

  handleNextPage() {
    this.handleSetPage(null, (p) => p + 1);
  }
  handlePrevPage() {
    this.handleSetPage(null, (p) => p - 1);
  }
  handleScale(amount) {
    this.setState((state) => ({
      scale: Math.max(Math.min(state.scale + amount, 3), 0.1),
    }));
  }

  render() {
    const { page, pdf, scale } = this.state;
    const { open, toggle, titulo } = this.props;
    return (
      <Modal
        isOpen={open}
        toggle={toggle}
        className="modal-full-height pdf"
        contentClassName="border-0 p-0"
      >
        <Container className="d-flex flex-column py-2 gap-2">
          <Row>
            <Col xs={{ size: 8, offset: 2 }}>
              <h5 className="text-center">{titulo}</h5>
            </Col>
            <Col xs={2} className="d-flex justify-content-end">
              <button
                className="btn-close"
                onClick={toggle}
                aria-label="Close"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs={8} className="d-flex">
              <Button
                onClick={this.handlePrevPage.bind(this)}
                disabled={page <= 1}
              >
                <ArrowLeftIcon />
              </Button>

              <div className="d-flex paginas align-items-center gap-2 justify-content-center">
                <Input
                  onChange={this.handleSetPage.bind(this)}
                  value={page}
                  className="w-50"
                />
                <span>de {isNaN(+pdf?.numPages) ? 1 : +pdf?.numPages}</span>
              </div>

              <Button
                onClick={this.handleNextPage.bind(this)}
                disabled={page >= pdf?.numPages}
              >
                <ArrowRightIcon />
              </Button>
            </Col>
            <Col xs={4}>
              <div className="d-flex justify-content-center gap-2">
                <Button
                  onPointerDown={this.handleScale.bind(this, -0.2)}
                  disabled={scale <= 0.1}
                >
                  <MagnifyMinusOutlineIcon />
                </Button>
                <Button
                  onPointerDown={this.handleScale.bind(this, 0.2)}
                  disabled={scale >= 3}
                >
                  <MagnifyPlusOutlineIcon />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div className="loading-overlay">
          <Spinner />
    </div> */}
        <div className="overflow-auto">
          <canvas ref={this.canvas} />
        </div>
      </Modal>
    );
  }
}

PdfModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  data: PropTypes.any,
};
PdfModal.defaultProps = {
  open: false,
  toggle: () => {},
};

export default PdfModal;
