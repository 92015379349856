import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { Field, Form, FormSpy } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import PasswordField from '@/shared/components/form/Password';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { formatFields, formatarCpf, telefoneMask } from '@/utils/helpers';
import { validate } from './validate';

export const DadosAcesso = ({
  isHorizontal,
  isAboveError,
  props,
  handleSubmit,
  handleIsRedirect,
}) => {
  const [todos, setTodos] = useState(false);
  const associado = useSelector((state) => state.associados.associado || {});
  const isFetching = useSelector((state) => state.associados.isFetching);
  const isUpdating = useSelector((state) => state.associados.isUpdating);
  const { id } = props.match.params;
  const isAddMode = !id;
  const formRef = useRef({});

  useEffect(() => {
    if (todos === true) {
      formRef.current.change('checkBoxEmail', true);
      formRef.current.change('midiaSociais', true);
      formRef.current.change('sms', true);
    }
    if (todos === false) {
      formRef.current.change('checkBoxEmail', false);
      formRef.current.change('midiaSociais', false);
      formRef.current.change('sms', false);
    }
  }, [todos]);
  const handleTodos = () => {
    setTodos((prev) => !prev);
  };

  return (
    <Card>
      <CardBody>
        {(isFetching || isUpdating) && (
          <Spinner className="table-fetch-spinner" />
        )}
        {(associado || isAddMode) && !isFetching && !isUpdating && (
          <Form
            onSubmit={handleSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : associado}
          >
            {({ handleSubmit, form }) => {
              formRef.current = form;
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => {
                      const fields = [
                        { name: 'cpf', mask: formatarCpf },
                        { name: 'telCelular', mask: telefoneMask },
                      ];
                      formatFields(fields, formRef);
                    }}
                  />
                  <Container>
                    <Row>
                      <Col xl={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">E-mail</span>
                          <div className="form__form-group-field">
                            <Field
                              name="email"
                              type="email"
                              component={FormField}
                              rules={{ required: false }}
                              isAboveError={isAboveError}
                              placeholder="Digite o E-mail"
                              maxLength="70"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Senha</span>
                          <div className="form__form-group-field">
                            <Field
                              name="password"
                              component={PasswordField}
                              placeholder="Senha"
                              className="input-without-border-radius"
                              keyIcon
                              maxLength="12"
                              isAboveError={isAboveError}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Confirmar Senha
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="passwordConfirm"
                              component={PasswordField}
                              placeholder="Confirme sua nova senha"
                              className="input-without-border-radius"
                              keyIcon
                              isAboveError={isAboveError}
                            />
                          </div>
                        </div>
                      </Col>
                      <Row className="my-3">
                        <Col xl={12}>
                          <span className="form__form-group-label">
                            <b>
                              Considerando o disposto na LGPD - LEI Nº 13.709,
                              DE 14 DE AGOSTO DE 2018, favor preencher o campo
                              abaixo:{' '}
                            </b>
                            <br></br>
                            Autorizo que a Ascesa envie, para meu conhecimento,
                            informações sobre a administração e gestão da
                            Associação e campanhas promocionais relacionadas aos
                            parceiros da Ascesa. Favor, escolha o(s) meio(s):
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="todos"
                              component={renderCheckBoxField}
                              label="Todos"
                              type="checkbox"
                              className="mb-0 mt-2"
                              onClick={handleTodos}
                              isAboveError={isAboveError}
                            />
                          </div>
                        </Col>

                        <Col xl={12}>
                          <div className="form__form-group-field">
                            <Field
                              name="checkBoxEmail"
                              component={renderCheckBoxField}
                              label="E-mail"
                              type="checkbox"
                              className="mb-0 mt-2"
                            />
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="form__form-group-field">
                            <Field
                              name="midiaSociais"
                              component={renderCheckBoxField}
                              label="Mídias Sociais (Whatsapp e Facebook)"
                              type="checkbox"
                              className="mb-0 mt-2"
                            />
                          </div>
                        </Col>
                        <Col xl={12}>
                          <div className="form__form-group-field">
                            <Field
                              name="sms"
                              component={renderCheckBoxField}
                              label="SMS"
                              type="checkbox"
                              className="mb-0 mt-2"
                            />
                          </div>
                        </Col>
                      </Row>
                      <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit">
                          Salvar
                        </Button>
                        <Button
                          type="button"
                          onClick={() => {
                            handleIsRedirect(true);
                          }}
                        >
                          Cancelar
                        </Button>
                      </ButtonToolbar>
                    </Row>
                  </Container>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
