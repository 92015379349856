import { handleActions } from 'redux-actions';

import {
  fetchRequisicoesPendentesRequest,
  fetchRequisicoesPendentesSuccess,
  fetchRequisicoesPendentesError,
} from '../actions/processoActions';

const defaultState = {
  requisicoes: [],
  totalPages: 0,
  requisicao: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};
export default handleActions(
  {
    [fetchRequisicoesPendentesRequest](state) {
      return {
        ...state,
        requisicoes: {},
        isFetching: true,
        error: null,
      };
    },
    [fetchRequisicoesPendentesSuccess](state, { payload }) {
      return {
        ...state,
        requisicoes: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchRequisicoesPendentesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
