import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import voucherApi from '../../utils/api/voucherApi';

export const getVoucherRequest = createAction('GET_VOUCHER_REQUEST');
export const getVoucherSuccess = createAction('GET_VOUCHER_SUCCESS');
export const getVoucherError = createAction('GET_VOUCHER_ERROR');

export const fetchVouchersRequest = createAction('FETCH_VOUCHERS_REQUEST');
export const fetchVouchersSuccess = createAction('FETCH_VOUCHERS_SUCCESS');
export const fetchVouchersError = createAction('FETCH_VOUCHERS_ERROR');

export const createVoucherRequest = createAction('CREATE_VOUCHER_REQUEST');
export const createVoucherSuccess = createAction('CREATE_VOUCHER_SUCCESS');
export const createVoucherError = createAction('CREATE_VOUCHER_ERROR');

export const updateVoucherRequest = createAction('UPDATE_VOUCHER_REQUEST');
export const updateVoucherSuccess = createAction('UPDATE_VOUCHER_SUCCESS');
export const updateVoucherError = createAction('UPDATE_VOUCHER_ERROR');

export const deleteVoucherRequest = createAction('DELETE_VOUCHER_REQUEST');
export const deleteVoucherSuccess = createAction('DELETE_VOUCHER_SUCCESS');
export const deleteVoucherError = createAction('DELETE_VOUCHER_ERROR');

export const fetchSearchVouchersRequest = createAction(
  'FETCH_SEARCH_VOUCHERS_REQUEST'
);
export const fetchSearchVouchersSuccess = createAction(
  'FETCH_SEARCH_VOUCHERS_SUCCESS'
);
export const fetchSearchVouchersError = createAction(
  'FETCH_SEARCH_VOUCHERS_ERROR'
);

export const newVoucherRequest = createAction('NEW_VOUCHER_REQUEST');

export const getVoucher = (id) => async (dispatch) => {
  try {
    dispatch(getVoucherRequest());
    const { data } = await voucherApi.getVoucher(id);
    dispatch(getVoucherSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getVoucherError(e));
    dispatch(displayError(e));
  }
};

export const fetchVouchers = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchVouchersRequest());
    const { data } = await voucherApi.fetchVouchers(page, pageSize);
    dispatch(fetchVouchersSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchVouchersError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchVouchers =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchVouchersRequest());
      const { data } = await voucherApi.searchVouchers(page, pageSize, search);
      dispatch(fetchSearchVouchersSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchVouchersError(e));
      dispatch(displayError(e));
    }
  };

export const createVoucher = (form) => async (dispatch) => {
  try {
    dispatch(createVoucherRequest());
    const { data } = await voucherApi.createVoucher(form);
    dispatch(displaySuccess('Serviço incluído com sucesso!'));
    dispatch(createVoucherSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createVoucherError(e));
    dispatch(displayError(e));
  }
};

export const updateVoucher = (id, form) => async (dispatch) => {
  try {
    dispatch(updateVoucherRequest());
    const { data } = await voucherApi.updateVoucher(id, form);
    dispatch(displaySuccess('Voucher atualizado com sucesso'));
    dispatch(updateVoucherSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateVoucherError(e));
    dispatch(displayError(e));
  }
};

export const deleteVoucher = (id) => async (dispatch) => {
  try {
    dispatch(deleteVoucherRequest());
    const { data } = await voucherApi.deleteVoucher(id);
    dispatch(deleteVoucherSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteVoucherError(e));
    dispatch(displayError(e));
  }
};

export const newVoucher = () => async (dispatch) => {
  dispatch(newVoucherRequest());
};
