import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import assembleiasApi from '../../utils/api/assembleiaApi';

export const getAssembleiaRequest = createAction('GET_ASSEMBLEIA_REQUEST');
export const getAssembleiaSuccess = createAction('GET_ASSEMBLEIA_SUCCESS');
export const getAssembleiaError = createAction('GET_ASSEMBLEIA_ERROR');

export const resultadoAssembleiaRequest = createAction('GET_ASSEMBLEIA_RESULTADO_REQUEST');
export const resultadoAssembleiaSuccess = createAction('GET_ASSEMBLEIA_RESULTADO_SUCCESS');
export const resultadoAssembleiaError = createAction('GET_ASSEMBLEIA_RESULTADO_ERROR');

export const fetchAssembleiasRequest = createAction(
  'FETCH_ASSEMBLEIAS_REQUEST'
);
export const fetchAssembleiasSuccess = createAction(
  'FETCH_ASSEMBLEIAS_SUCCESS'
);
export const fetchAssembleiasError = createAction('FETCH_ASSEMBLEIAS_ERROR');

export const createAssembleiaRequest = createAction(
  'CREATE_ASSEMBLEIA_REQUEST'
);
export const createAssembleiaSuccess = createAction(
  'CREATE_ASSEMBLEIA_SUCCESS'
);
export const createAssembleiaError = createAction('CREATE_ASSEMBLEIA_ERROR');

export const updateAssembleiaRequest = createAction(
  'UPDATE_ASSEMBLEIA_REQUEST'
);
export const updateAssembleiaSuccess = createAction(
  'UPDATE_ASSEMBLEIA_SUCCESS'
);
export const updateAssembleiaError = createAction('UPDATE_ASSEMBLEIA_ERROR');

export const adicionarDocumentoRequest = createAction(
  'UPDATE_DOCUMENTO_REQUEST'
);
export const adicionarDocumentoSuccess = createAction(
  'UPDATE_DOCUMENTO_SUCCESS'
);
export const adicionarDocumentoError = createAction('UPDATE_DOCUMENTO_ERROR');

export const deleteDocumentoRequest = createAction(
  'DELETE_DOCUMENTO_REQUEST'
);
export const deleteDocumentoSuccess = createAction(
  'DELETE_DOCUMENTO_SUCCESS'
);
export const deleteDocumentoError = createAction('DELETE_DOCUMENTO_ERROR');

export const deleteAssembleiaRequest = createAction(
  'DELETE_ASSEMBLEIA_REQUEST'
);
export const deleteAssembleiaSuccess = createAction(
  'DELETE_ASSEMBLEIA_SUCCESS'
);
export const deleteAssembleiaError = createAction('DELETE_ASSEMBLEIA_ERROR');

export const fetchSearchAssembleiasRequest = createAction(
  'FETCH_SEARCH_ASSEMBLEIAS_REQUEST'
);
export const fetchSearchAssembleiasSuccess = createAction(
  'FETCH_SEARCH_ASSEMBLEIAS_SUCCESS'
);
export const fetchSearchAssembleiasError = createAction(
  'FETCH_SEARCH_ASSEMBLEIAS_ERROR'
);

export const newAssembleiaRequest = createAction('NEW_ASSEMBLEIA_REQUEST');

export const getAssembleia = (id) => async (dispatch) => {
  try {
    dispatch(getAssembleiaRequest());
    const { data } = await assembleiasApi.readAssembleia(id);
    dispatch(getAssembleiaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getAssembleiaError(e));
    dispatch(displayError(e));
  }
};

export const resultadoAssembleia = (id) => async (dispatch) => {
  try {
    dispatch(resultadoAssembleiaRequest());
    const { data } = await assembleiasApi.resultadoAssembleia(id);
    dispatch(resultadoAssembleiaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(resultadoAssembleiaError(e));
    dispatch(displayError(e));
  }
};

export const fetchAssembleias = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchAssembleiasRequest());
    const { data } = await assembleiasApi.fetchAssembleias(page, pageSize);
    dispatch(fetchAssembleiasSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAssembleiasError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchAssembleias =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchAssembleiasRequest());
      const { data } = await assembleiasApi.searchAssembleias(
        page,
        pageSize,
        search
      );
      dispatch(fetchSearchAssembleiasSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchAssembleiasError(e));
      dispatch(displayError(e));
    }
  };

export const createAssembleia = (form) => async (dispatch) => {
  try {
    dispatch(createAssembleiaRequest());
    const { data } = await assembleiasApi.createAssembleia(form);
    dispatch(displaySuccess('Assembléia incluída com sucesso!'));
    dispatch(createAssembleiaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createAssembleiaError(e));
    dispatch(displayError(e));
  }
};

export const updateAssembleia = (id, form) => async (dispatch) => {
  try {
    dispatch(updateAssembleiaRequest());
    const { data } = await assembleiasApi.updateAssembleia(id, form);
    dispatch(displaySuccess('Assembléia atualizada com sucesso'));
    dispatch(updateAssembleiaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateAssembleiaError(e));
    dispatch(displayError(e));
  }
};

export const adicionarDocumento = (id, form) => async (dispatch) => {
  try {
    dispatch(adicionarDocumentoRequest());
    const { data } = await assembleiasApi.adicionarDocumento(id, form);
    dispatch(displaySuccess('Documento adicionado com sucesso'));
    dispatch(adicionarDocumentoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(adicionarDocumentoError(e));
    dispatch(displayError(e));
  }
};

export const deleteDocumento = (id, _id) => async (dispatch) => {
  try {
    dispatch(deleteDocumentoRequest());
    const { data } = await assembleiasApi.deleteDocumento(id, _id);
    dispatch(deleteDocumentoSuccess(data.message));
    dispatch(displaySuccess('Documento Deletado com sucesso'));
  } catch (e) {
    console.log(e);
    dispatch(deleteDocumentoError(e));
    dispatch(displayError(e));
  }
};

export const deleteAssembleia = (id) => async (dispatch) => {
  try {
    dispatch(deleteAssembleiaRequest());
    const { data } = await assembleiasApi.deleteAssembleia(id);
    dispatch(deleteAssembleiaSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteAssembleiaError(e));
    dispatch(displayError(e));
  }
};

export const newAssembleia = () => async (dispatch) => {
  dispatch(newAssembleiaRequest());
};
