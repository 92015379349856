import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Popover } from 'reactstrap';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

const ChromeColorPickerField = ({ onChange, name, ...props }) => {
  const [colorValue, setColorValue] = useState(props.value);
  const [chromeColorActive, setChromeColorActive] = useState({
    displayColorPicker: false,
    active: false,
  });

  const { hex, rgb } = colorValue;
  const { displayColorPicker, active } = chromeColorActive;

  const handleClick = (e) => {
    e.preventDefault();
    setChromeColorActive({
      displayColorPicker: !displayColorPicker,
      active: !active,
    });
  };
  const handleChange = (changeColor) => {
    setColorValue({ hex: changeColor.hex, rgb: changeColor.rgb });
    onChange(changeColor);
  };

  return (
    <div className="color-picker">
      <button
        type="button"
        className={`color-picker__button${active ? ' active' : ''}`}
        onClick={handleClick}
        id={name}
      >
        <p className="color-picker__color">{hex}</p>
        <div
          className="color-picker__color-view"
          style={{ backgroundColor: hex }}
        />
      </button>
      <Popover
        isOpen={displayColorPicker}
        target={name}
        placement="bottom"
        className="color-picker__popover"
      >
        <ChromePicker color={rgb} onChange={handleChange} />
      </Popover>
    </div>
  );
};

ChromeColorPickerField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default renderComponentField(ChromeColorPickerField);
