import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '@/shared/components/form/FormField';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import Modal from '../../../shared/components/Modal';
import { listaCategoria, telefoneMask } from '../../../utils/helpers';
import renderSelectField from '@/shared/components/form/Select';

import {
  ButtonToolbar,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { Link, useHistory } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import {
  fetchSearchServicos,
  deleteServico,
} from '../../../redux/actions/servicoActions';
import validate from './validate';
import { useMemo } from 'react';
import SearchIcon from 'mdi-react/SearchIcon';

export const ServicosList = () => {
  const dispatch = useDispatch();
  const servicos = useSelector((state) => state.servicos?.servicos);
  const totalItems = useSelector((state) => state.servicos?.totalItems);
  const isFetching = useSelector((state) => state.servicos?.isFetching);
  const totalPages = useSelector((state) => state.servicos?.totalPages);
  const isUpdating = useSelector((state) => state.servicos?.isUpdating);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});
  const history = useHistory();

  const colunas = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'nome',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Categoria',
        accessor: 'categoria',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Endereço',
        accessor: 'endereco',
        disableGlobalFilter: true,
        width: 80,
      },

      {
        Header: 'Telefone',
        accessor: 'telefone',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Ação',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 80,
      },
    ],
    []
  );

  const dadosTabela = (servicos) => {
    return servicos.map((servico) => ({
      nome: servico.nome,
      categoria: servico.categoria,
      endereco: servico.endereco,
      telefone: telefoneMask(servico.telefone),
      acao: (
        <div className="list__icon-btn-group">
          <button
            className="icon-btn success"
            id="editar"
            type="button"
            onClick={() => history.push(`/servico/editar/${servico._id}`)}
          >
            <EditIcon />
          </button>
          <UncontrolledTooltip placement="left" target="editar">
            Editar
          </UncontrolledTooltip>
          <Modal
            color="danger"
            title="Excluir Serviço"
            accept="Excluir"
            dismiss="Cancelar"
            btn={
              <button className="icon-btn danger" id="excluir" type="button">
                <TrashIcon />
              </button>
            }
            onAccept={async () => {
              await dispatch(deleteServico(servico._id)).then(setPage(0));
            }}
          >
            <span>
              Você realmente deseja excluir <br /> o servico&nbsp;
              <b>{servico.nome}</b> permanentemente?
            </span>
          </Modal>
          <UncontrolledTooltip placement="left" target="excluir">
            Excluir
          </UncontrolledTooltip>
        </div>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchSearchServicos(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.filters = {
      search: data.search,
      categoria: data.categoria.value,
    };

    setSearch(searchForm);
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Serviços cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link
                className="btn btn-primary list__btn-add"
                to={'/servico/novo'}
              >
                <PlusIcon /> Novo Serviço
              </Link>
            </ButtonToolbar>
          </div>
          <div className="search_form">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col md={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          component={FormField}
                          placeholder="Pesquisa..."
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="categoria"
                          component={renderSelectField}
                          type="text"
                          placeholder="Categoria"
                          options={(() =>
                            [{ value: undefined, label: 'Todos' }].concat(
                              listaCategoria
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} md={4} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      <SearchIcon /> Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {servicos && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(servicos)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
