/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo Nome não pode estar vazio';
  }
  if (!values.dataAberturaDatePicker) {
    errors.dataAberturaDatePicker =
      'O campo Data da abertura não pode estar vazio';
  }
  if (!values.dataEncerramentoDatePicker) {
    errors.dataEncerramentoDatePicker =
      'O campo Data do encerramento não pode estar vazio';
  }
  if (!values.chapasSelect) {
    errors.chapasSelect = 'O campo Chapas não pode estar vazio';
  }
  if (!values.statusSelect) {
    errors.statusSelect = 'O campo Situação não pode estar vazio';
  }
  return errors;
};

const validateMensagem = (values) => {
  if (!values.assunto) {
    errors.assunto = 'O campo Assunto não pode estar vazio';
  }
  if (!values.mensagem) {
    errors.mensagem = 'O campo Mensagem não pode estar vazio';
  }
}

export {validate, validateMensagem};
