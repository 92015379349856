import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '@/shared/components/form/FormField';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import {
  ButtonToolbar,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { Link, useHistory } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import { fetchSearchAssociados } from '../../../redux/actions/associadoActions';
import { BadgeAssociado } from '../../../shared/components/Badges';
import validate from './validate';
import { useMemo } from 'react';
import SearchIcon from 'mdi-react/SearchIcon';
import { telefoneMask } from '../../../utils/helpers';

export const AssociadosList = () => {
  const dispatch = useDispatch();
  const associados = useSelector((state) => state.associados?.associados);
  const totalItems = useSelector((state) => state.associados?.totalItems);
  const isFetching = useSelector((state) => state.associados?.isFetching);
  const totalPages = useSelector((state) => state.associados?.totalPages);
  const isUpdating = useSelector((state) => state.associados?.isUpdating);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});
  const history = useHistory();

  const colunas = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'nome',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Telefone',
        accessor: 'telCelular',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Situação',
        accessor: 'situacao',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Ação',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 80,
      },
    ],
    []
  );

  const dadosTabela = (associados) => {
    return associados.map((associado) => ({
      nome: associado.nome,
      email: associado.email,
      telCelular: telefoneMask(associado.telCelular),
      situacao: <BadgeAssociado situacao={associado.situacao} />,
      acao: (
        <div className="list__icon-btn-group">
          <button
            className="icon-btn success"
            id="editar"
            type="button"
            onClick={() => history.push(`/associado/editar/${associado._id}`)}
          >
            <EditIcon />
          </button>
          <UncontrolledTooltip placement="left" target="editar">
            Editar
          </UncontrolledTooltip>
        </div>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchSearchAssociados(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    setSearch(searchForm);
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text d-inline-block">Associados cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link
                className="btn btn-primary list__btn-add"
                to={'/associado/novo'}
              >
                <PlusIcon /> Novo Associado
              </Link>
            </ButtonToolbar>
          </div>
          <div className="search_form">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col md={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          component={FormField}
                          placeholder="Pesquisa..."
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} md={4} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      <SearchIcon /> Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {associados && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(associados)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
