import { deleteRequest, get, post, put } from './base/index';
import { getUserId } from '../helpers';
import { API } from '../constants';

export default {
  getBanner: (id) => get(`${API}/admin/banner/${id}/${getUserId()}`),
  fetchBanners: (page, pageSize) =>
    get(
      `${API}/admin/banners/${getUserId()}?page=${page}&pageSize=${pageSize}`
    ),
  searchBanners: (page, pageSize, pesquisa) =>
    post(
      `${API}/admin/banners/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      pesquisa
    ),
  deleteBanner: (id) =>
    deleteRequest(`${API}/admin/banner/${id}/${getUserId()}`),
  createBanner: (form) =>
    post(`${API}/admin/banner/create/${getUserId()}`, form),
  updateBanner: (id, form) =>
    put(`${API}/admin/banner/${id}/${getUserId()}`, form),
};
