import { createAction } from 'redux-actions';
import { displayError } from './notificacoesActions';
import processoApi from '../../utils/api/processoApi';

export const fetchRequisicoesPendentesRequest = createAction(
  'FETCH_REQUISICOES_PENDENTES_REQUEST'
);
export const fetchRequisicoesPendentesSuccess = createAction(
  'FETCH_REQUISICOES_PENDENTES_SUCCESS'
);
export const fetchRequisicoesPendentesError = createAction(
  'FETCH_REQUISICOES_PENDENTES_ERROR'
);

export const fetchRequisicoesPendentes =
  (page, pageSize) => async (dispatch) => {
    try {
      dispatch(fetchRequisicoesPendentesRequest());
      const { data } = await processoApi.listRequisicoesPendentes(
        page,
        pageSize
      );
      dispatch(fetchRequisicoesPendentesSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchRequisicoesPendentesError(e));
      dispatch(displayError(e));
    }
  };
