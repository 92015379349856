import { deleteRequest, get, post, put } from './base/index';
import { getUserId } from '../helpers';
import { API } from '../constants';

export default {
  getServico: (id) => get(`${API}/admin/servico/${id}/${getUserId()}`),
  fetchServicos: (page, pageSize) =>
    get(
      `${API}/admin/servicos/${getUserId()}?page=${page}&pageSize=${pageSize}`
    ),
  searchServicos: (page, pageSize, pesquisa) =>
    post(
      `${API}/admin/servicos/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      pesquisa
    ),
  deleteServico: (id) =>
    deleteRequest(`${API}/admin/servico/${id}/${getUserId()}`),
  createServico: (form) =>
    post(`${API}/admin/servico/create/${getUserId()}`, form),
  updateServico: (id, form) =>
    put(`${API}/admin/servico/${id}/${getUserId()}`, form),
};
