/* eslint-disable */
const validateDocument = (values) => {
  const errors = {};
  if (!values.titulo) {
    errors.titulo = "O campo Título não pode estar vazio";
  }
  if (!values.files) {
    errors.files = "O campo Documento não pode estar vazio";
  }
  return errors;
};

export { validateDocument };
