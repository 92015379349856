import { Modal, ModalFooter, Button } from "reactstrap";
import { Field, Form, FormSpy } from "react-final-form";
import { useDispatch } from 'react-redux';
import FormField from "@/shared/components/form/FormField";
import { useState } from "react";
import renderDropZoneField from "@/shared/components/form/DropZone";

import TrashIcon from "mdi-react/TrashIcon";
import PdfIcon from "@/shared/img/assets/pdf.svg";
import DocIcon from "@/shared/img/assets/doc.svg";
import DocxIcon from "@/shared/img/assets/docx.svg";
import { adicionarDocumento } from "../../../redux/actions/assembleiaActions";
import { validateDocument } from "./validateDocument";

const DocumentoFormModal = ({ open, toggleModal, isAboveError, id }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState();

  const onSubmit = (data) => {
    const formData = new FormData();
    const fields = ['titulo'];
    fields.forEach((item) => {
      formData.set(item, data[item]);
    });
    if (data.files && data.files.length > 0) {
      formData.append('file', data.files[0]);
    }

    dispatch(adicionarDocumento(id, formData))
    toggleModal(false)
    setFile()
  };

  return (
    <Modal isOpen={open} className="modal-novo-documento theme-light">
      <Form onSubmit={onSubmit} validate={validateDocument}>
        {({ handleSubmit, form: { change } }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                if (values.files && values.files[0]) {
                  let file = values.files[0];
                  if (/\.pdf$/.test(file.name)) {
                    file.icone = PdfIcon;
                  }
                  if (/\.doc$/.test(file.name)) {
                    file.icone = DocIcon;
                  }
                  if (/\.docx$/.test(file.name)) {
                    file.icone = DocxIcon;
                  }

                  setFile(file);
                }
              }}
            />
            <h3 className="page-title">Documento Anexo</h3>
            <div className="form__form-group">
              <div className="group-modal-input">
                <span>Nome</span>
                <div>
                  <Field
                    name="titulo"
                    type="text"
                    component={FormField}
                    rules={{ required: "Campo Obrigatório" }}
                    isAboveError={isAboveError}
                    placeholder="Nome do Documento"
                    maxLength="30"
                  />
                </div>
              </div>

              {!file && (
                <>
                  <Field
                    name="files"
                    component={renderDropZoneField}
                    tipoArquivo="application/pdf"
                  />
                  <h6>Extensões Permitidas: *.pdf</h6>
                </>
              )}
              {file && (
                <div className="documento-modal-fileinfo">
                  <img src={file.icone} className="svg-tipo-arquivo" />
                  <span>{file.name}</span>
                  <button
                    className="icon-btn danger trash-icon"
                    id="excluir"
                    type="button"
                    onClick={() => {
                      setFile();
                      change("files", null);
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              )}
            </div>
            <ModalFooter className="modal-footer-novo-documento">
              <Button color="primary" type="submit">
                Salvar
              </Button>
              <Button onClick={() => toggleModal(false)}>Voltar</Button>
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default DocumentoFormModal;
