import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import associadosApi from '../../utils/api/associadoApi';

export const getAssociadoRequest = createAction('GET_ASSOCIADO_REQUEST');
export const getAssociadoSuccess = createAction('GET_ASSOCIADO_SUCCESS');
export const getAssociadoError = createAction('GET_ASSOCIADO_ERROR');

export const fetchAssociadosRequest = createAction('FETCH_ASSOCIADOS_REQUEST');
export const fetchAssociadosSuccess = createAction('FETCH_ASSOCIADOS_SUCCESS');
export const fetchAssociadosError = createAction('FETCH_ASSOCIADOS_ERROR');

export const createAssociadoRequest = createAction('CREATE_ASSOCIADO_REQUEST');
export const createAssociadoSuccess = createAction('CREATE_ASSOCIADO_SUCCESS');
export const createAssociadoError = createAction('CREATE_ASSOCIADO_ERROR');

export const updateAssociadoRequest = createAction('UPDATE_ASSOCIADO_REQUEST');
export const updateAssociadoSuccess = createAction('UPDATE_ASSOCIADO_SUCCESS');
export const updateAssociadoError = createAction('UPDATE_ASSOCIADO_ERROR');

export const deleteAssociadoRequest = createAction('DELETE_ASSOCIADO_REQUEST');
export const deleteAssociadoSuccess = createAction('DELETE_ASSOCIADO_SUCCESS');
export const deleteAssociadoError = createAction('DELETE_ASSOCIADO_ERROR');

export const fetchSearchAssociadosRequest = createAction(
  'FETCH_SEARCH_ASSOCIADOS_REQUEST'
);
export const fetchSearchAssociadosSuccess = createAction(
  'FETCH_SEARCH_ASSOCIADOS_SUCCESS'
);
export const fetchSearchAssociadosError = createAction(
  'FETCH_SEARCH_ASSOCIADOS_ERROR'
);

export const newAssociadoRequest = createAction('NEW_ASSOCIADO_REQUEST');

export const aprovarAssociadoRequest = createAction(
  'APROVAR_ASSOCIADO_REQUEST'
);
export const aprovarAssociadoSuccess = createAction(
  'APROVAR_ASSOCIADO_SUCCESS'
);
export const aprovarAssociadoError = createAction('APROVAR_ASSOCIADO_ERROR');

export const reprovarAssociadoRequest = createAction(
  'REPROVAR_ASSOCIADO_REQUEST'
);
export const reprovarAssociadoSuccess = createAction(
  'REPROVAR_ASSOCIADO_SUCCESS'
);
export const reprovarAssociadoError = createAction('REPROVAR_ASSOCIADO_ERROR');

export const suspenderAssociadoRequest = createAction(
  'SUSPENDER_ASSOCIADO_REQUEST'
);
export const suspenderAssociadoSuccess = createAction(
  'SUSPENDER_ASSOCIADO_SUCCESS'
);
export const suspenderAssociadoError = createAction(
  'SUSPENDER_ASSOCIADO_ERROR'
);

export const getStatsAssociadosRequest = createAction(
  'GET_STATS_ASSOCIADOS_REQUEST'
);
export const getStatsAssociadosSuccess = createAction(
  'GET_STATS_ASSOCIADOS_SUCCESS'
);
export const getStatsAssociadosError = createAction(
  'GET_STATS_ASSOCIADOS_ERROR'
);

export const getAssociado = (id) => async (dispatch) => {
  try {
    dispatch(getAssociadoRequest());
    const { data } = await associadosApi.getAssociado(id);
    dispatch(getAssociadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const fetchAssociados = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchAssociadosRequest());
    const { data } = await associadosApi.fetchAssociados(page, pageSize);
    dispatch(fetchAssociadosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAssociadosError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchAssociados =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchAssociadosRequest());
      const { data } = await associadosApi.searchAssociados(
        page,
        pageSize,
        search
      );
      dispatch(fetchSearchAssociadosSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchAssociadosError(e));
      dispatch(displayError(e));
    }
  };

export const createAssociado = (form) => async (dispatch) => {
  try {
    dispatch(createAssociadoRequest());
    const { data } = await associadosApi.createAssociado(form);
    dispatch(displaySuccess('Associado incluído com sucesso!'));
    dispatch(createAssociadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const updateAssociado = (id, form) => async (dispatch) => {
  try {
    dispatch(updateAssociadoRequest());
    const { data } = await associadosApi.updateAssociado(id, form);
    dispatch(displaySuccess('Associado atualizado com sucesso'));
    dispatch(updateAssociadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const deleteAssociado = (id) => async (dispatch) => {
  try {
    dispatch(deleteAssociadoRequest());
    const { data } = await associadosApi.deleteAssociado(id);
    dispatch(deleteAssociadoSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const newAssociado = () => async (dispatch) => {
  dispatch(newAssociadoRequest());
};

export const aprovarAssociado = (id) => async (dispatch) => {
  try {
    dispatch(updateAssociadoRequest());
    const { data } = await associadosApi.aprovarAssociado(id);
    dispatch(updateAssociadoSuccess(data.message));
    dispatch(displaySuccess('Aprovação realizada com sucesso'));
  } catch (e) {
    console.log(e);
    dispatch(updateAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const reprovarAssociado = (id) => async (dispatch) => {
  try {
    dispatch(updateAssociadoRequest());
    const { data } = await associadosApi.reprovarAssociado(id);
    dispatch(updateAssociadoSuccess(data.message));
    dispatch(displaySuccess('Reprovação realizada com sucesso'));
  } catch (e) {
    console.log(e);
    dispatch(updateAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const suspenderAssociado = (id) => async (dispatch) => {
  try {
    dispatch(updateAssociadoRequest());
    const { data } = await associadosApi.suspenderAssociado(id);
    dispatch(updateAssociadoSuccess(data.message));
    dispatch(displaySuccess('Suspenção realizada com sucesso'));
  } catch (e) {
    console.log(e);
    dispatch(updateAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const reativarAssociado = (id) => async (dispatch) => {
  try {
    dispatch(updateAssociadoRequest());
    const { data } = await associadosApi.reativarAssociado(id);
    dispatch(updateAssociadoSuccess(data.message));
    dispatch(displaySuccess('Reativação realizada com sucesso'));
  } catch (e) {
    console.log(e);
    dispatch(updateAssociadoError(e));
    dispatch(displayError(e));
  }
};

export const getStatsAssociados = () => async (dispatch) => {
  try {
    dispatch(getStatsAssociadosRequest());
    const { data } = await associadosApi.getStatsAssociados();
    dispatch(getStatsAssociadosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getStatsAssociadosError(e));
    dispatch(displayError(e));
  }
};
