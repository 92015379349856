import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Spinner, Card, CardBody } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import renderChromeColorPickerField from '@/shared/components/form/ChromeColorPicker';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getChapa,
  createChapa,
  updateChapa,
  newChapa,
} from '@/redux/actions/chapaActions';

const ChapaForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const chapa = useSelector((state) => state.chapas.chapa);
  const isFetching = useSelector((state) => state.chapas.isFetching);
  const error = useSelector((state) => state.chapas.error);
  const { id } = props.match.params;
  const isAddMode = !id;

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getChapa(id));
    } else {
      dispatch(newChapa());
    }
  }, [dispatch, id, isAddMode]);

  const onSubmit = (data) => {
    const formData = {};
    const fields = Object.keys(data).map((key, i) => key);
    fields.map((item) => (formData[item] = data[item]));
    isAddMode
      ? dispatch(createChapa(formData))
      : dispatch(updateChapa(id, formData));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/chapas" />;
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">{isAddMode ? 'Nova' : 'Editar'} Chapa</h4>
        </div>
        {isFetching && <Spinner className="table-fetch-spinner" />}
        {(chapa || isAddMode) && !isFetching && (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : chapa}
          >
            {({ handleSubmit, form: { reset, pristine, submitting } }) => (
              <form
                className={`form ${isHorizontal && 'form--horizontal'}`}
                onSubmit={handleSubmit}
              >
                <div className="form__form-group">
                  <span className="form__form-group-label">Número</span>
                  <div className="form__form-group-field">
                    <Field
                      name="numero"
                      type="number"
                      component={FormField}
                      rules={{ required: 'Campo Obrigatório' }}
                      isAboveError={isAboveError}
                      placeholder="Número da chapa"
                      maxlength="15"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Nome</span>
                  <div className="form__form-group-field">
                    <Field
                      name="nome"
                      type="text"
                      component={FormField}
                      isAboveError={isAboveError}
                      placeholder="Nome da chapa"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Slogan</span>
                  <div className="form__form-group-field">
                    <Field
                      name="slogan"
                      component={FormField}
                      placeholder="Slogan da chapa"
                      type="text"
                      isAboveError={isAboveError}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Cor</span>
                  <div className="form__form-group-field">
                    <Field
                      name="cor"
                      component={renderChromeColorPickerField}
                      placeholder="#ffffff"
                      isAboveError={isAboveError}
                    />
                  </div>
                </div>

                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Salvar
                  </Button>
                  <Button type="button" onClick={() => setIsRedirect(true)}>
                    Cancelar
                  </Button>
                </ButtonToolbar>
              </form>
            )}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};

ChapaForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

ChapaForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default ChapaForm;
