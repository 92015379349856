export const validate = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo Nome não pode estar vazio';
  }
  if (!values.categoria) {
    errors.categoria = 'O campo Categoria não pode estar vazio';
  }
  if (!values.endereco) {
    errors.endereco = 'O campo Endereço não pode estar vazio';
  }
  if (!values.situacao) {
    errors.situacao = 'O campo Situação não pode estar vazio';
  }
  if (!values.oferta) {
    errors.oferta = 'O campo Oferta não pode estar vazio';
  }

  return errors;
};
