import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import noticiasApi from '../../utils/api/noticiaApi';

export const getNoticiaRequest = createAction('GET_NOTICIA_REQUEST');
export const getNoticiaSuccess = createAction('GET_NOTICIA_SUCCESS');
export const getNoticiaError = createAction('GET_NOTICIA_ERROR');

export const fetchNoticiasRequest = createAction('FETCH_NOTICIAS_REQUEST');
export const fetchNoticiasSuccess = createAction('FETCH_NOTICIAS_SUCCESS');
export const fetchNoticiasError = createAction('FETCH_NOTICIAS_ERROR');

export const createNoticiaRequest = createAction('CREATE_NOTICIA_REQUEST');
export const createNoticiaSuccess = createAction('CREATE_NOTICIA_SUCCESS');
export const createNoticiaError = createAction('CREATE_NOTICIA_ERROR');

export const updateNoticiaRequest = createAction('UPDATE_NOTICIA_REQUEST');
export const updateNoticiaSuccess = createAction('UPDATE_NOTICIA_SUCCESS');
export const updateNoticiaError = createAction('UPDATE_NOTICIA_ERROR');

export const deleteNoticiaRequest = createAction('DELETE_NOTICIA_REQUEST');
export const deleteNoticiaSuccess = createAction('DELETE_NOTICIA_SUCCESS');
export const deleteNoticiaError = createAction('DELETE_NOTICIA_ERROR');

export const fetchSearchNoticiasRequest = createAction(
  'FETCH_SEARCH_NOTICIAS_REQUEST'
);
export const fetchSearchNoticiasSuccess = createAction(
  'FETCH_SEARCH_NOTICIAS_SUCCESS'
);
export const fetchSearchNoticiasError = createAction(
  'FETCH_SEARCH_NOTICIAS_ERROR'
);

export const newNoticiaRequest = createAction('NEW_NOTICIA_REQUEST');

export const getNoticia = (id) => async (dispatch) => {
  try {
    dispatch(getNoticiaRequest());
    const { data } = await noticiasApi.getNoticia(id);
    dispatch(getNoticiaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getNoticiaError(e));
    dispatch(displayError(e));
  }
};

export const fetchNoticias = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchNoticiasRequest());
    const { data } = await noticiasApi.fetchNoticias(page, pageSize);
    dispatch(fetchNoticiasSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchNoticiasError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchNoticias =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchNoticiasRequest());
      const { data } = await noticiasApi.searchNoticias(page, pageSize, search);
      dispatch(fetchSearchNoticiasSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchNoticiasError(e));
      dispatch(displayError(e));
    }
  };

export const createNoticia = (form) => async (dispatch) => {
  try {
    dispatch(createNoticiaRequest());
    const { data } = await noticiasApi.createNoticia(form);
    dispatch(displaySuccess('Noticia incluído com sucesso!'));
    dispatch(createNoticiaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createNoticiaError(e));
    dispatch(displayError(e));
  }
};

export const updateNoticia = (id, form) => async (dispatch) => {
  try {
    dispatch(updateNoticiaRequest());
    const { data } = await noticiasApi.updateNoticia(id, form);
    dispatch(displaySuccess('Noticia atualizado com sucesso'));
    dispatch(updateNoticiaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateNoticiaError(e));
    dispatch(displayError(e));
  }
};

export const deleteNoticia = (id) => async (dispatch) => {
  try {
    dispatch(deleteNoticiaRequest());
    const { data } = await noticiasApi.deleteNoticia(id);
    dispatch(deleteNoticiaSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteNoticiaError(e));
    dispatch(displayError(e));
  }
};

export const newNoticia = () => async (dispatch) => {
  dispatch(newNoticiaRequest());
};

// export const apagandoImgNoticia = () => async (dispatch) => {
//   dispatch(apagandoImgNoticiaRequest());
// } ...state.noticias, imagem: null
