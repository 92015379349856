import { handleActions } from 'redux-actions';

import {
  getNoticiaRequest,
  getNoticiaSuccess,
  getNoticiaError,
  fetchNoticiasRequest,
  fetchNoticiasSuccess,
  fetchNoticiasError,
  fetchSearchNoticiasRequest,
  fetchSearchNoticiasSuccess,
  fetchSearchNoticiasError,
  createNoticiaRequest,
  createNoticiaSuccess,
  createNoticiaError,
  updateNoticiaRequest,
  updateNoticiaSuccess,
  updateNoticiaError,
  deleteNoticiaRequest,
  deleteNoticiaSuccess,
  deleteNoticiaError,
  newNoticiaRequest,
} from '../actions/noticiaActions';

const defaultState = {
  noticias: [],
  totalPages: 0,
  noticia: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getNoticiaRequest](state) {
      return {
        ...state,
        noticia: {},
        isFetching: true,
        error: null,
      };
    },
    [getNoticiaSuccess](state, { payload }) {
      return {
        ...state,
        noticia: payload,
        isFetching: false,
        error: null,
      };
    },
    [getNoticiaError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchNoticiasRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchNoticiasSuccess](state, { payload }) {
      return {
        ...state,
        noticias: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchNoticiasError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchNoticiasRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchNoticiasSuccess](state, { payload }) {
      return {
        ...state,
        noticias: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchNoticiasError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createNoticiaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createNoticiaSuccess](state, { payload }) {
      return {
        ...state,
        noticia: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createNoticiaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateNoticiaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateNoticiaSuccess](state, { payload }) {
      return {
        ...state,
        noticia: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateNoticiaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteNoticiaRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteNoticiaSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteNoticiaError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newNoticiaRequest](state, { payload }) {
      return {
        ...state,
        noticia: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
  },
  defaultState
);
