import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Spinner,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Field, Form } from 'react-final-form';
import moment from 'moment';
import PropTypes from 'prop-types';
import FormField from '@/shared/components/form/FormField';
import AddIcon from 'mdi-react/AddIcon';
import arrayMutators from 'final-form-arrays';
import rendeRadioField from '@/shared/components/form/RadioButton';
import TrashIcon from 'mdi-react/TrashIcon';
import { FieldArray } from 'react-final-form-arrays';
import { formatarCpf } from '@/utils/helpers';
import { validate } from './validate';

const Dependentes = ({
  isHorizontal,
  isAboveError,
  props,
  handleSubmit,
  handleIsRedirect,
}) => {
  const associado = useSelector((state) => state.associados.associado);
  const isFetching = useSelector((state) => state.associados.isFetching);
  const isUpdating = useSelector((state) => state.associados.isUpdating);
  const { id } = props.match.params;
  const isAddMode = !id;
  const listDependente = {
    nome: '',
    sexo: '',
    dataNascimento: '',
    cpf: '',
  };
  const formRef = useRef({});

  if (associado) {
    associado.dependentes = associado.dependentes?.map((dependente) => ({
      nome: dependente.nome,
      sexo: dependente.sexo,
      dataNascimento: moment(dependente.dataNascimento).format('yyyy-MM-DD'),
      cpf: dependente.cpf,
    }));
  }

  return (
    <>
      {(isFetching || isUpdating) && (
        <Spinner className="table-fetch-spinner" />
      )}
      {(associado || isAddMode) && !isFetching && !isUpdating && (
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={isAddMode ? {} : associado}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({
            handleSubmit,
            form,
            form: {
              mutators: { push, remove },
            },
          }) => {
            formRef.current = form;
            return (
              <form
                className={`form ${isHorizontal && 'form--horizontal'}`}
                onSubmit={handleSubmit}
              >
                <Container>
                  <FieldArray name="dependentes">
                    {({ fields }) =>
                      fields?.map((dependente, index) => (
                        <Row key={index}>
                          <Col xl={3}>
                            <div className="form__form-group-dependente-">
                              <span className="form__form-group-label">
                                Nome
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name={`${dependente}.nome`}
                                  component={FormField}
                                  type="string"
                                  rules={{
                                    required: 'Campo Obrigatório',
                                  }}
                                  isAboveError={isAboveError}
                                  placeholder="Nome do dependente"
                                  maxLength="100"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Sexo
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name={`${dependente}.sexo`}
                                  component={rendeRadioField}
                                  label="Masculino"
                                  radioValue="masculino"
                                  className="p-0"
                                />
                                <Field
                                  name={`${dependente}.sexo`}
                                  component={rendeRadioField}
                                  label="Feminino"
                                  radioValue="feminino"
                                  className="p-0"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xl={3}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                Data de Nascimento
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name={`${dependente}.dataNascimento`}
                                  type="date"
                                  placeholder="dd/mm/aaaa"
                                  component={FormField}
                                  rules={{ required: false }}
                                  isAboveError={isAboveError}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xl={2}>
                            <div className="form__form-group">
                              <span className="form__form-group-label">
                                CPF
                              </span>
                              <div className="form__form-group-field">
                                <Field
                                  name={`${dependente}.cpf`}
                                  component={FormField}
                                  type="string"
                                  rules={{
                                    required: 'Campo Obrigatório',
                                  }}
                                  isAboveError={isAboveError}
                                  placeholder="Digite o CPF"
                                  format={(cpf) =>
                                    cpf ? formatarCpf(cpf) : ''
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xl={1}>
                            <div className="grid-center">
                              <div className="form__form-group-categoria-icone">
                                <button
                                  className="icon-btn danger"
                                  type="button"
                                  onClick={() => {
                                    remove('dependente', listDependente);
                                  }}
                                >
                                  <TrashIcon />
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ))
                    }
                  </FieldArray>
                  <div className="form__form-group text-right">
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => {
                        push('dependentes', listDependente);
                      }}
                    >
                      <AddIcon /> Dependentes
                    </Button>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => handleIsRedirect(true)}
                    >
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </Container>
              </form>
            );
          }}
        </Form>
      )}
    </>
  );
};

Dependentes.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

Dependentes.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default Dependentes;
