import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  authReducer,
  notificacoesReducer,
  usuarioReducer,
  assembleiaReducer,
  associadoReducer,
  cepReducer,
  chapaReducer,
  servicoReducer,
  voucherReducer,
  noticiaReducer,
  bannerReducer,
  processoReducer,
  mensagemReducer,
} from '@/redux/reducers/index';

const reducer = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  auth: authReducer,
  notificacoes: notificacoesReducer,
  usuarios: usuarioReducer,
  assembleias: assembleiaReducer,
  associados: associadoReducer,
  cep: cepReducer,
  chapas: chapaReducer,
  servicos: servicoReducer,
  vouchers: voucherReducer,
  noticias: noticiaReducer,
  banners: bannerReducer,
  processos: processoReducer,
  mensagens: mensagemReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
