import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import bannersApi from '../../utils/api/bannerApi';

export const getBannerRequest = createAction('GET_BANNER_REQUEST');
export const getBannerSuccess = createAction('GET_BANNER_SUCCESS');
export const getBannerError = createAction('GET_BANNER_ERROR');

export const fetchBannersRequest = createAction('FETCH_BANNERS_REQUEST');
export const fetchBannersSuccess = createAction('FETCH_BANNERS_SUCCESS');
export const fetchBannersError = createAction('FETCH_BANNERS_ERROR');

export const createBannerRequest = createAction('CREATE_BANNER_REQUEST');
export const createBannerSuccess = createAction('CREATE_BANNER_SUCCESS');
export const createBannerError = createAction('CREATE_BANNER_ERROR');

export const updateBannerRequest = createAction('UPDATE_BANNER_REQUEST');
export const updateBannerSuccess = createAction('UPDATE_BANNER_SUCCESS');
export const updateBannerError = createAction('UPDATE_BANNER_ERROR');

export const deleteBannerRequest = createAction('DELETE_BANNER_REQUEST');
export const deleteBannerSuccess = createAction('DELETE_BANNER_SUCCESS');
export const deleteBannerError = createAction('DELETE_BANNER_ERROR');

export const fetchSearchBannersRequest = createAction(
  'FETCH_SEARCH_BANNERS_REQUEST'
);
export const fetchSearchBannersSuccess = createAction(
  'FETCH_SEARCH_BANNERS_SUCCESS'
);
export const fetchSearchBannersError = createAction(
  'FETCH_SEARCH_BANNERS_ERROR'
);

export const newBannerRequest = createAction('NEW_BANNER_REQUEST');

export const getBanner = (id) => async (dispatch) => {
  try {
    dispatch(getBannerRequest());
    const { data } = await bannersApi.getBanner(id);
    dispatch(getBannerSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getBannerError(e));
    dispatch(displayError(e));
  }
};

export const fetchBanners = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchBannersRequest());
    const { data } = await bannersApi.fetchBanners(page, pageSize);
    dispatch(fetchBannersSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchBannersError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchBanners =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchBannersRequest());
      const { data } = await bannersApi.searchBanners(page, pageSize, search);
      dispatch(fetchSearchBannersSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchBannersError(e));
      dispatch(displayError(e));
    }
  };

export const createBanner = (form) => async (dispatch) => {
  try {
    dispatch(createBannerRequest());
    const { data } = await bannersApi.createBanner(form);
    dispatch(displaySuccess('Banner incluído com sucesso!'));
    dispatch(createBannerSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createBannerError(e));
    dispatch(displayError(e));
  }
};

export const updateBanner = (id, form) => async (dispatch) => {
  try {
    dispatch(updateBannerRequest());
    const { data } = await bannersApi.updateBanner(id, form);
    dispatch(displaySuccess('Banner atualizado com sucesso'));
    dispatch(updateBannerSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateBannerError(e));
    dispatch(displayError(e));
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  try {
    dispatch(deleteBannerRequest());
    const { data } = await bannersApi.deleteBanner(id);
    dispatch(deleteBannerSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteBannerError(e));
    dispatch(displayError(e));
  }
};

export const newBanner = () => async (dispatch) => {
  dispatch(newBannerRequest());
};

// export const apagandoImgBanner = () => async (dispatch) => {
//   dispatch(apagandoImgBannerRequest());
// } ...state.banners, imagem: null
