import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormField, {
  renderComponentField,
} from '@/shared/components/form/FormField';
import { Button, ButtonToolbar, Card, CardBody, Spinner } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import {
  createMensagem,
  getMensagem,
  newMensagemRequest,
} from '../../../redux/actions/mensagemActions';
import { validate } from './validate';

export const MensagemForm = ({ isHorizontal, isAboveError, props }) => {
  const TextArea = renderComponentField('textarea');
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const mensagem = useSelector((state) => state.mensagens.mensagem);
  const isFetching = useSelector((state) => state.mensagens.isFetching);
  const isUpdating = useSelector((state) => state.mensagens.isUpdating);
  let error = useSelector((state) => state.mensagens.error);
  const { id } = props.match.params;
  const isAddMode = !id;
  const formRef = useRef({});

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getMensagem(id));
    } else {
      dispatch(newMensagemRequest());
    }
  }, [dispatch, id, isAddMode]);

  const onSubmit = (values) => {
    dispatch(createMensagem(values));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/mensagens" />;
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">{isAddMode ? 'Nova' : 'Ver'} Mensagem</h4>
        </div>
        {(isFetching || isUpdating) && (
          <Spinner className="table-fetch-spinner" />
        )}
        {(mensagem || isAddMode) && !isFetching && !isUpdating && (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : mensagem}
          >
            {({ handleSubmit, form, form: {} }) => {
              formRef.current = form;
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">Assunto</span>
                    <div className="form__form-group-field">
                      <Field
                        disabled={!isAddMode}
                        name="assunto"
                        component={FormField}
                        type="string"
                        rules={{ required: 'Campo Obrigatório' }}
                        isAboveError={isAboveError}
                        placeholder="Digite o Assunto"
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Mensagem</span>
                    <div className="form__form-group-field">
                      <Field
                        disabled={!isAddMode}
                        name="mensagem"
                        type="text"
                        component={TextArea}
                        isAboveError={isAboveError}
                        placeholder="Digite a Mensagem"
                        maxLength="1024"
                      />
                    </div>
                  </div>

                  <ButtonToolbar className="form__button-toolbar">
                    {isAddMode && (
                      <Button color="primary" type="submit">
                        Enviar
                      </Button>
                    )}
                    <Button
                      type="button"
                      onClick={() => {
                        setIsRedirect(true);
                        error = !error;
                      }}
                    >
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
