import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { ServicosList } from './components/ServicosList';

const Servicos = ({ rtl }) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Serviços</h3>
          <h3 className="page-subhead subhead">
            Crie ou atualize o cadastro de Serviços na plataforma.
          </h3>
        </Col>
      </Row>
      <Row>
        <ServicosList />
      </Row>
    </Container>
  );
};

export default Servicos;
