import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import AssembleiaForm from './components/AssembleiaForm';

const Assembleia = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Assembléia</h3>
          <h3 className="page-subhead subhead">
            Adicione uma nova assembléia ou altere suas informações
          </h3>
        </Col>
      </Row>
      <AssembleiaForm isHorizontal={false} isAboveError props={props} />
    </Container>
  );
};

export default Assembleia;
