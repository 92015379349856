import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import CheckIcon from 'mdi-react/CheckIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import SearchIcon from 'mdi-react/SearchIcon';
import FormField from '@/shared/components/form/FormField';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import Modal from '@/shared/components/Modal';
import { BadgeAssociado } from '@/shared/components/Badges';
import {
  aprovarAssociado,
  fetchSearchAssociados,
  reprovarAssociado,
} from '@/redux/actions/associadoActions';
import { getUserProfile } from '@/redux/actions/authActions';

export const ProcessosList = () => {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.auth?.usuario);
  const associados = useSelector((state) => state.associados?.associados);
  const totalItems = useSelector((state) => state.associados?.totalItems);
  const isFetching = useSelector((state) => state.associados?.isFetching);
  const totalPages = useSelector((state) => state.associados?.totalPages);
  const isUpdating = useSelector((state) => state.associados?.isUpdating);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({
    filters: { situacao: 'pendente' },
  });
  const history = useHistory();

  const colunas = useMemo(
    () => [
      {
        Header: 'Associado',
        accessor: 'nome',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Data da Solicitação',
        accessor: 'createdAt',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Situação',
        accessor: 'situacao',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'Ação',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 80,
      },
    ],
    []
  );

  const dadosTabela = (associados) => {
    return associados.map((associado) => ({
      createdAt: moment(associado.createdAt).format('DD/MM/YYYY HH:mm'),
      nome: associado.nome,
      situacao: <BadgeAssociado situacao={associado.situacao} />,
      acao: !associado.aprovacao.find(
        (aprov) => aprov.perfil === usuario.perfil && aprov.dataHora
      ) ? (
        <div className="list__icon-btn-group">
          <button
            className="icon-btn success"
            id="aprovar"
            type="button"
            onClick={() => handleAprovarAssociado(associado._id)}
          >
            <CheckIcon />
          </button>
          <UncontrolledTooltip placement="left" target="aprovar">
            Aprovar Associação
          </UncontrolledTooltip>
          <Modal
            color="danger"
            title="Reprovar requisição"
            accept="Reprovar"
            dismiss="Cancelar"
            btn={
              <button className="icon-btn danger" id="reprovar" type="button">
                <CancelIcon />
              </button>
            }
            onAccept={() => handleReprovarAssociado(associado._id)}
          >
            <span>
              Você realmente deseja reprovar <br /> a requisição de&nbsp;
              <b>{associado.nome}</b> permanentemente?
            </span>
          </Modal>
          <UncontrolledTooltip placement="left" target="reprovar">
            Reprovar Associação
          </UncontrolledTooltip>
        </div>
      ) : (
        `Aguardando ${associado.aprovacao.map((aprov) => !(aprov.perfil === usuario.perfil && aprov.dataHora) ? aprov.perfil: '').join(', ')}...`
      ),
    }));
  };

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(fetchSearchAssociados(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    searchForm.filters = { situacao: 'pendente' };
    setSearch(searchForm);
  };

  const handleAprovarAssociado = (id) => {
    dispatch(aprovarAssociado(id));
  };

  const handleReprovarAssociado = (id) => {
    dispatch(reprovarAssociado(id));
  };

  return (
    <Col lg={9}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className="bold-text">Solicitações pendentes</h4>
          </div>
          <div className="search_form">
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col md={7}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          component={FormField}
                          placeholder="Pesquisa..."
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={5} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      <SearchIcon /> Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {associados && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(associados)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
