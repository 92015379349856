import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { UsuariosList } from './components/UsuariosList';

const Usuarios = ({ rtl }) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Administradores</h3>
          <h3 className="page-subhead subhead">
            Crie ou atualize o cadastro de Administradores na plataforma.
          </h3>
        </Col>
      </Row>
      <Row>
        <UsuariosList />
      </Row>
    </Container>
  );
};

export default Usuarios;
