import { get, post, put, deleteRequest } from './base/index';
import { getUserId } from '../helpers';

export default {
  createAssembleia: (form) =>
    post(`/admin/assembleia/create/${getUserId()}`, form),
  readAssembleia: (id) => get(`/admin/assembleia/${id}/${getUserId()}`),
  updateAssembleia: (id, form) =>
    put(`/admin/assembleia/${id}/${getUserId()}`, form),
  deleteAssembleia: (id) =>
    deleteRequest(`/admin/assembleia/${id}/${getUserId()}`),
  listAssembleias: (page, pageSize) =>
    get(`/admin/assembleias/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  resultadoAssembleia: (id) =>
    get(`/admin/assembleia/resultado/${id}/${getUserId()}`),
  searchAssembleias: (page, pageSize, searchForm) =>
    post(
      `/admin/assembleias/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
  adicionarDocumento: (id, form) =>
    put(`/admin/assembleia/adicioardocumento/${id}/${getUserId()}`, form),
  deleteDocumento: (id, _id) =>
    deleteRequest(`/admin/assembleia/removerdocumento/${id}/${_id}/${getUserId()}`),
};
