import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import { Field, Form, FormSpy } from 'react-final-form';

import FormField from '@/shared/components/form/FormField';
import SelectField from '@/shared/components/form/Select';
import renderDropZoneField from '@/shared/components/form/DropZone';
import {
  createBanner,
  getBanner,
  newBannerRequest,
  updateBanner,
} from '../../../redux/actions/bannerActions';
import { listaSituacaoBanner, arrayToBase64 } from '../../../utils/helpers';
import { validate } from './validate';

import TrashIcon from 'mdi-react/TrashIcon';

export const BannerForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const [removerImagem, setRemoverImagem] = useState(false);
  const banner = useSelector((state) => state.banners.banner);
  const isFetching = useSelector((state) => state.banners.isFetching);
  const isUpdating = useSelector((state) => state.banners.isUpdating);
  let error = useSelector((state) => state.banners.error);
  const { id } = props.match.params;
  const isAddMode = !id;
  const formRef = useRef({});

  const img = useMemo(
    () =>
      banner?.imagem?.data?.data
        ? arrayToBase64(banner?.imagem?.data?.data)
        : null,
    [banner]
  );

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getBanner(id));
    } else {
      dispatch(newBannerRequest());
    }
  }, [dispatch, id, isAddMode]);

  useEffect(() => {
    if (removerImagem) {
      banner.imagem = [];
      setRemoverImagem(false);
    }
  }, [removerImagem, banner]);

  const onSubmit = (data) => {
    const formData = new FormData();
    const fields = ['titulo', 'situacao', 'link', 'textoBotao', 'ordem'];
    fields.forEach((item) => {
      formData.set(item, data[item]);
    });
    if (data.files && data.files.length > 0) {
      formData.append('file', data.files[0]);
    }

    formData.set('situacao', data.situacao.value);
    if (!data['link']) {
      formData.set('link', '');
    }
    isAddMode
      ? dispatch(createBanner(formData))
      : dispatch(updateBanner(id, formData));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/banners" />;
  }

  if (banner) {
    banner.situacao = listaSituacaoBanner.find(
      (item) => item.value === banner.situacao
    );
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">{isAddMode ? 'Novo' : 'Editar'} Banner</h4>
        </div>
        {(isFetching || isUpdating) && (
          <Spinner className="table-fetch-spinner" />
        )}
        {(banner || isAddMode) && !isFetching && !isUpdating && (
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={isAddMode ? {} : banner}
          >
            {({ handleSubmit, form, form: {} }) => {
              formRef.current = form;
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => {}}
                  />
                  <Col lg={12}>
                    <Row>
                      <Col md={8} className="p-0">
                        <div className="form__form-group">
                          <span className="form__form-group-label">Título</span>
                          <div className="form__form-group-field">
                            <Field
                              name="titulo"
                              component={FormField}
                              type="string"
                              rules={{ required: 'Campo Obrigatório' }}
                              isAboveError={isAboveError}
                              placeholder="Digite o titulo"
                              maxLength="120"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Situação
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="situacao"
                              type="text"
                              component={SelectField}
                              className="form-control mb-2 registration-input-box"
                              rules={{ required: 'Campo Obrigatório' }}
                              isAboveError={isAboveError}
                              placeholder="Selecione uma opção"
                              options={listaSituacaoBanner}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4} className="p-0">
                        <div className="form__form-group">
                          <span className="form__form-group-label">Link</span>
                          <div className="form__form-group-field">
                            <Field
                              name="link"
                              component={FormField}
                              type="string"
                              rules={{ required: true }}
                              isAboveError={isAboveError}
                              placeholder="Digite o Link"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4} className="pr-0">
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Texto do Botão
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="textoBotao"
                              component={FormField}
                              type="string"
                              rules={{ required: true }}
                              isAboveError={isAboveError}
                              placeholder="Digite o texto do Botão"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">Ordem</span>
                          <div className="form__form-group-field">
                            <Field
                              name="ordem"
                              component={FormField}
                              type="number"
                              rules={{ required: 'Campo Obrigatório' }}
                              isAboveError={isAboveError}
                              placeholder="Digite a ordem"
                              min={'0'}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} className="pl-0">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Imagem de Capa do Banner
                      </span>
                      {(!img || banner?.imagem?.data?.data === undefined) && (
                        <Field name="files" component={renderDropZoneField} />
                      )}
                      {img && banner?.imagem?.data?.data?.length > 0 && (
                        <div className="banner__imagem_container">
                          <div className="banner__imagem_botao position-relative">
                            <img
                              className="banner__imagem"
                              src={img}
                              alt="Imagem da Notícia"
                            />
                            <Button
                              color="danger"
                              className="btn-sm m-3"
                              onClick={(e) => {
                                setRemoverImagem(true);
                              }}
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                              }}
                            >
                              <TrashIcon /> Remover
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* <Col md={8}>
                    <div className="d-flex justify-content-center">
                      <img src={banner.imagem} />
                    </div>
                  </Col> */}
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        setIsRedirect(true);
                        error = !error;
                      }}
                    >
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
