import { get, post, put, deleteRequest } from './base/index';
import { getUserId } from '../helpers';

export default {
  createChapa: (form) => post(`/admin/chapa/create/${getUserId()}`, form),
  readChapa: (id) => get(`/admin/chapa/${id}/${getUserId()}`),
  updateChapa: (id, form) => put(`/admin/chapa/${id}/${getUserId()}`, form),
  deleteChapa: (id) => deleteRequest(`/admin/chapa/${id}/${getUserId()}`),
  listChapas: (page, pageSize) =>
    get(`/admin/chapas/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  searchChapas: (page, pageSize, searchForm) =>
    post(
      `/admin/chapas/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
};
