import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormField from '@/shared/components/form/FormField';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import renderSelectField from '@/shared/components/form/Select';
import {
  ButtonToolbar,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
} from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import PlusIcon from 'mdi-react/PlusIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { fetchSearchVouchers } from '../../../redux/actions/voucherActions';
import SearchIcon from 'mdi-react/SearchIcon';
import { BadgeSituacao } from '../../../shared/components/Badges';
import moment from 'moment';
import { fetchServicos } from '../../../redux/actions/servicoActions';
import { fetchAssociados } from '../../../redux/actions/associadoActions';

export const VouchersList = () => {
  const dispatch = useDispatch();
  const vouchers = useSelector((state) => state.vouchers?.vouchers);
  const totalItems = useSelector((state) => state.vouchers?.totalItems);
  const servicos = useSelector((state) => state.servicos.servicos);
  const associados = useSelector((state) => state.associados.associados);
  const isFetching = useSelector((state) => state.vouchers?.isFetching);
  const totalPages = useSelector((state) => state.vouchers?.totalPages);
  const isUpdating = useSelector((state) => state.vouchers?.isUpdating);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});

  const colunas = useMemo(() => [
    {
      Header: 'Associado',
      accessor: 'associado',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Serviço',
      accessor: 'servico',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Situação',
      accessor: 'situacao',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Emitido em',
      accessor: 'createdAt',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Válido até',
      accessor: 'vencimento',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'Ação',
      accessor: 'acao',
      disableGlobalFilter: true,
      width: 80,
    },
  ]);

  const dadosTabela = (vouchers) => {
    return vouchers.map((voucher) => ({
      associado: voucher.associado?.nome,
      servico: voucher.servico?.nome,
      situacao: <BadgeSituacao status={voucher.situacao} />,
      createdAt: moment(voucher.createdAt).format('DD/MM/YYYY'),
      vencimento: moment(voucher.vencimento).format('DD/MM/YYYY'),
      acao: (
        <div className="list__icon-btn-group">
          <Link to={`/voucher/ver/${voucher._id}`}>
            <button className="icon-btn primary" id="ver" type="button">
              <EyeIcon />
            </button>
          </Link>
        </div>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchSearchVouchers(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search, isUpdating]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  useEffect(() => {
    dispatch(fetchServicos(1, 9999));
    dispatch(fetchAssociados(1, 9999));
  }, [dispatch]);

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.filters = {
      search: data.search,
      servico: data.servico?.value || undefined,
      associado: data.associado?.value || undefined,
    };
    setSearch(searchForm);
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Vouchers cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link
                className="btn btn-primary list__btn-add"
                to={'/voucher/novo'}
              >
                <PlusIcon /> Novos Voucher
              </Link>
            </ButtonToolbar>
          </div>
          <div className="search_form">
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col md={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="associado"
                          component={renderSelectField}
                          type="text"
                          placeholder="Associado"
                          options={(() =>
                            [].concat(
                              [{ value: '', label: 'Todos' }],
                              associados.map((item) => ({
                                label: item.nome,
                                value: item._id,
                              }))
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="servico"
                          component={renderSelectField}
                          type="text"
                          placeholder="Serviço"
                          options={(() =>
                            [].concat(
                              [{ value: '', label: 'Todos' }],
                              servicos.map((item) => ({
                                label: item.nome,
                                value: item._id,
                              }))
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} md={4} className="align-self-end text-end">
                    <Button color="primary" type="submit" className="btn-sm">
                      <SearchIcon /> Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {vouchers && !isFetching && !isUpdating && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(vouchers)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};
