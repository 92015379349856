import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import {
  getAssembleia,
  resultadoAssembleia,
} from "@/redux/actions/assembleiaActions";

import moment from "moment";
import { BadgeParticipante } from "../../../shared/components/Badges";

export const ResultadoAssembleia = ({ props }) => {
  const dispatch = useDispatch();
  const assembleia = useSelector((state) => state.assembleias.assembleia);
  const resultado = useSelector((state) => state.assembleias.resultado);
  const [isRedirect, setIsRedirect] = useState(false);
  const isFetching = useSelector((state) => state.noticias.isFetching);
  const isUpdating = useSelector((state) => state.noticias.isUpdating);
  let error = useSelector((state) => state.noticias.error);
  const { id } = props.match.params;
  const isAddMode = !id;

  useEffect(() => {
    if (isUpdating) return;
    if (!isAddMode) {
      dispatch(getAssembleia(id));
    }
  }, [dispatch, id, isAddMode, isUpdating]);

  useEffect(() => {
    if (isUpdating) return;
    if (!isAddMode) {
      dispatch(resultadoAssembleia(id));
    }
  }, [dispatch, id, isAddMode, isUpdating]);

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/assembleias" />;
  }

  function aprovaçãoDocumentoResultado(result) {
    if (!result) return;
    let aprovacao = "";

    if (result.aprovado > result.reprovado) {
      aprovacao = "Aprovado";
    } else if (result.aprovado < result.reprovado) {
      aprovacao = "Reprovado";
    } else if (result.aprovado === result.reprovado) {
      aprovacao = "Empate";
    }

    return aprovacao;
  }

  function chapaVencedora(chapas) {
    if (!chapas) return;
    let indice = 0;
    chapas.reduce((maiorNumero, chapa, i) => {
      if (maiorNumero < chapa.votos) {
        maiorNumero = chapa.votos;
        indice = i;
      }
      return maiorNumero;
    }, 0);

    return chapas[indice];
  }

  return (
    <>
      <Row>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="card__title">
                  <h4 className="bold-text">{assembleia?.nome}</h4>
                </div>
              </Col>
              <Col>
                <div className="card__title">
                  <h4 className="bold-text">
                    Data:{" "}
                    {moment(assembleia?.dataEncerramento).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </h4>
                </div>
              </Col>
            </Row>
            <div className="card__title mt-0">
              <h4 className="bold-text">
                Aprovação de Documentos da Gestão Atual
              </h4>
            </div>
            {(isFetching || isUpdating) && (
              <Spinner className="table-fetch-spinner" />
            )}
            <Container>
              <Row>
                <Col lg={6}>
                  <Row className="gap-4">
                    <th className="">Aprovado: </th>
                    <td className="text-success">
                      {resultado?.aprovacaoDocumentos?.aprovado} -{" "}
                      {resultado?.aprovacaoDocumentos?.percentualAprovado}
                    </td>
                    <th className="">Reprovado: </th>
                    <td className="text-danger">
                      {resultado?.aprovacaoDocumentos?.reprovado} -{" "}
                      {resultado?.aprovacaoDocumentos?.percentualReprovado}
                    </td>
                  </Row>
                </Col>
              </Row>
              <Row className="">
                <Col className="text-center">
                  <h1 className="h4" style={{ color: "#212529" }}>
                    Resultado:{" "}
                    {aprovaçãoDocumentoResultado(
                      resultado?.aprovacaoDocumentos
                    )}
                  </h1>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <div className="card__title">
              <h4 className="bold-text">
                Chapas Concorrentes para Próximo Mandato
              </h4>
            </div>
            <Container>
              <Row>
                <Col lg={4}>
                  <Row>
                    <th className="">Chapas:</th>
                  </Row>
                  <Row>
                    <Table responsive borderless className="">
                      {resultado?.chapas?.map((chapa) => (
                        <tr>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="cor-chapa"
                                style={{
                                  border: `2px solid ${chapa?.chapa?.cor?.hex}`,
                                  backgroundColor: `${chapa?.chapa?.cor?.hex}`,
                                }}
                              ></button>
                              {chapa?.chapa?.numero} - {chapa?.chapa?.nome}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </Table>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    {/* <Col className="d-flex gap-4"></Col> */}
                    <th className="">Votos:</th>
                  </Row>
                  <Table responsive borderless className="">
                    {resultado?.chapas?.map((chapa) => (
                      <tr>
                        <td className="pl-0">{chapa?.percentualVotos}</td>
                      </tr>
                    ))}
                  </Table>
                </Col>
              </Row>
              <Row className="">
                <Col className="text-center">
                  <div className="h4 d-flex align-items-center gap-2 justify-content-center">
                    Chapa Vencedora:{" "}
                      <button
                        className="cor-chapa"
                        style={{
                          border: `2px solid ${
                            chapaVencedora(resultado?.chapas)?.chapa.cor.hex
                          }`,
                          backgroundColor: `${
                            chapaVencedora(resultado?.chapas)?.chapa.cor.hex
                          }`,
                        }}
                      ></button>
                    {chapaVencedora(resultado?.chapas)?.chapa.numero} -{' '}
                    {chapaVencedora(resultado?.chapas)?.chapa.nome}
                  </div>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <div className="card__title">
              <h4 className="bold-text">Lista de Presença</h4>
            </div>
            {
              resultado?.participantes?.reduce(
                (total, participante) => {
                  if (
                    participante.aprovacaoDocumentos === "aprovado" ||
                    participante.aprovacaoDocumentos === "reprovado"
                  ) {
                    total.presente++;
                  }
                  if (!participante.aprovacaoDocumentos) {
                    total.ausente++;
                  }
                  total.text = (
                    <tr className="d-flex gap-5">
                      <td>Presente: {total.presente}</td>{" "}
                      <td>Ausentes: {total.ausente}</td>
                      <td>Total: {total.presente + total.ausente}</td>
                    </tr>
                  );
                  return total;
                },
                { presente: 0, ausente: 0, text: "" }
              ).text
            }

            <Container>
              <Row>
                <Col lg={3}>
                  <Row>
                    <th>Participantes:</th>
                  </Row>
                  <Table responsive borderless>
                    {resultado?.participantes?.map((participante) => (
                      <tr>
                        <td>{participante?.associado?.nome}</td>
                      </tr>
                    ))}
                  </Table>
                </Col>
                <Col lg={3}>
                  <Row>
                    <th>Email:</th>
                  </Row>
                  <Table responsive borderless>
                    {resultado?.participantes?.map((participante) => (
                      <tr>
                        <td className="pl-0">
                          {participante?.associado?.email}
                        </td>
                      </tr>
                    ))}
                  </Table>
                </Col>
                <Col className="p-0">
                  <Row>
                    <th>Presença:</th>
                  </Row>
                  <Table responsive borderless>
                    {resultado?.participantes?.map((participante) => (
                      <tr>
                        <td className="pl-0">
                          {participante?.aprovacaoDocumentos ? (
                            <BadgeParticipante status={"Presente"} />
                          ) : (
                            <BadgeParticipante status={"Ausente"} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </Table>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Row>
    </>
  );
};
