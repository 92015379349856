export const validate = (values) => {
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo Nome não pode estar vazio';
  }
  if (!values.cpf) {
    errors.cpf = 'O campo CPF não pode estar vazio';
  }
  if (!values.rg) {
    errors.rg = 'O campo RG não pode estar vazio';
  }
  if (!values.dataNascimento) {
    errors.dataNascimento = 'O campo Data de nascimento não pode estar vazio';
  }
  if (!values.sexo) {
    errors.sexo = 'O campo Sexo não pode estar vazio';
  }
  if (!values.estadoCivil) {
    errors.estadoCivil = 'O campo Estado civil não pode estar vazio';
  }
  if (!values.matricula) {
    errors.matricula = 'O campo Matrícula não pode estar vazio';
  }
  if (!values.empresaVinculo) {
    errors.empresaVinculo =
      'O campo Empresa na qual trabalha não pode estar vazio';
  }
  if (!values.cep) {
    errors.cep = 'O campo CEP não pode estar vazio';
  }
  if (!values.endereco) {
    errors.endereco =
      'O campo Endereço completo (logradouro, número e complemento) não pode estar vazio';
  }
  if (!values.telCelular) {
    errors.telCelular = 'O campo Telefone não pode estar vazio';
  }
  if (!values.nomePai) {
    errors.nomePai = 'O campo Nome do pai não pode estar vazio';
  }
  if (!values.nomeMae) {
    errors.nomeMae = 'O campo Nome da mãe não pode estar vazio';
  }
  if (!values.email) {
    errors.email = 'O campo E-mail não pode estar vazio';
  }
  return errors;
};
