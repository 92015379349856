import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick, sidebarCollapse }) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        icon="home"
        title="Início"
        route="/dashboard"
        onClick={onClick}
      />
      <SidebarLink
        icon="users"
        title="Associados"
        route="/associados"
        onClick={onClick}
      />
        <SidebarLink
          icon="layers"
          title="Banners"
          route="/banners"
          onClick={onClick}
        />
      <SidebarLink
        icon="license"
        title="Campanhas"
        route="/noticias"
        onClick={onClick}
      />
      <SidebarLink
        icon="envelope"
        title="Mensagens"
        route="/mensagens"
        onClick={onClick}
      />
      <SidebarLink
        icon="user"
        title="Administradores"
        route="/administradores"
        onClick={onClick}
      />
    </ul>
    <ul className="sidebar__block">
      <SidebarCategory
        title="Processo eletivo"
        icon="cog"
        sidebarCollapse={sidebarCollapse}
      >
        <SidebarLink
          title="Assembléias"
          route="/assembleias"
          onClick={onClick}
        />
        <SidebarLink title="Chapas" route="/chapas" onClick={onClick} />
      </SidebarCategory>
    </ul>
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
