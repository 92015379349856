import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { AssociadosList } from './components/AssociadosList';

const Associados = (props) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Associados</h3>
          <h3 className="page-subhead subhead">
            Crie ou atualize o cadastro de Associados na plataforma.
          </h3>
        </Col>
      </Row>
      <Row>
        <AssociadosList props={props} />
      </Row>
    </Container>
  );
};

export default Associados;
