/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.numero) {
    errors.numero = 'O campo Número não pode estar vazio';
  }
  if (!values.nome) {
    errors.nome = 'O campo Nome não pode estar vazio';
  }

  return errors;
};

export default validate;
