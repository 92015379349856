import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormField from '@/shared/components/form/FormField';
import SelectField from '@/shared/components/form/Select';
import renderSelectField from '@/shared/components/form/Select';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import { Field, Form } from 'react-final-form';
import {
  createVoucher,
  newVoucherRequest,
} from '../../../redux/actions/voucherActions';
import { validate } from './validate';
import { fetchAssociados } from '../../../redux/actions/associadoActions';
import { fetchServicos } from '../../../redux/actions/servicoActions';
import moment from 'moment';

export const VoucherForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const associados = useSelector((state) => state.associados.associados);
  const servicos = useSelector((state) => state.servicos.servicos);
  const isFetching = useSelector((state) => state.vouchers.isFetching);
  const isUpdating = useSelector((state) => state.vouchers.isUpdating);
  const error = useSelector((state) => state.vouchers.error);

  useEffect(() => {
    dispatch(newVoucherRequest());
    dispatch(fetchAssociados(1, 9999));
    dispatch(fetchServicos(1, 9999));
  }, [dispatch]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      associado: values.associado.value,
      vencimento: moment(values.vencimento).toISOString(),
      servico: values.servico.value,
    };
    dispatch(createVoucher(data));
    setIsRedirect(true);
  };

  const handleVoltar = () => {
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    return <Redirect to="/vouchers" />;
  }

  return (
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">Voucher Novo</h4>
        </div>
        {isFetching && <Spinner className="table-fetch-spinner" />}
        {!isFetching && (
          <Form onSubmit={onSubmit} validate={validate}>
            {({ handleSubmit }) => {
              return (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Associado
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="associado"
                              component={SelectField}
                              isAboveError={isAboveError}
                              options={associados.map((item) => ({
                                label: item.nome,
                                value: item._id,
                              }))}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Serviço
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="servico"
                              component={renderSelectField}
                              isAboveError={isAboveError}
                              options={servicos.map((item) => ({
                                label: item.nome,
                                value: item._id,
                              }))}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            Vencimento
                          </span>
                          <div className="form__form-group-field">
                            <Field
                              name="vencimento"
                              type="date"
                              component={FormField}
                              isAboveError={isAboveError}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit">
                        Salvar
                      </Button>
                      <Button type="button" onClick={handleVoltar}>
                        Cancelar
                      </Button>
                    </ButtonToolbar>
                  </Container>
                </form>
              );
            }}
          </Form>
        )}
      </CardBody>
    </Card>
  );
};
