export const API = process.env.REACT_APP_API_URL;
export const JWT_KEY = 'ascesaAdmin';

export const regexNaN = /\D+/g;

// Máscara do Número de Telefone
export const regexMaskTelefone =
  /^(?<ddd>\d{2})(?<first>\d{1,5})?(?<last>\d{4})?$|(?<long>\d{12,})/;

export const listaEstadoCivil = [
  { label: 'casado', value: 'casado' },
  { label: 'solteiro', value: 'solteiro' },
  { label: 'viúvo', value: 'viúvo' },
  { label: 'divorciado', value: 'divorciado' },
  { label: 'outros', value: 'outros' },
];

export const listaEmpresas = [
  {
    label: 'Cooperado de cooperativa do SICOOB',
    value: 'Cooperado de cooperativa do SICOOB',
  },
  { label: 'BANCO SICOOB', value: 'BANCO SICOOB' },
  { label: 'SICOOB DTVM', value: 'SICOOB DTVM' },
  { label: 'SICOOB PAGAMENTOS', value: 'SICOOB PAGAMENTOS' },
  {
    label: 'Cooperativas centrais e singulares do SICOOB',
    value: 'Cooperativas centrais e singulares do SICOOB',
  },
  { label: 'Fundação SICOOB Previ', value: 'Fundação SICOOB Previ' },
  {
    label: 'SICOOB Administradora de Consórcio',
    value: 'SICOOB Administradora de Consórcio',
  },
  { label: 'SICOOB Confederação', value: 'SICOOB Confederação' },
  { label: 'SICOOB Seguradora', value: 'SICOOB Seguradora' },
];
