import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ButtonToolbar,
  Spinner,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Modal,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { Field, Form } from "react-final-form";

import PropTypes from "prop-types";
import moment from "moment";

import renderSelectField from "@/shared/components/form/Select";
import renderDatepickerField from "@/shared/components/form/DatepickerField";
import renderMultiSelectField from "@/shared/components/form/MultiSelect";

import FormField, {
  renderComponentField,
} from "@/shared/components/form/FormField";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { validate, validateMensagem } from "./validate";
import {
  getAssembleia,
  createAssembleia,
  updateAssembleia,
  newAssembleia,
  deleteDocumento,
} from "@/redux/actions/assembleiaActions";
import { createMensagem } from "../../../redux/actions/mensagemActions";
import { listaSituacaoAssembleia } from "../../../utils/helpers";
import { fetchChapas } from "../../../redux/actions/chapaActions";

import PdfModal from "./PdfModal";
import DocumentoFormModal from "./DocumentoFormModal";

import EyeIcon from "mdi-react/EyeIcon";
import TrashIcon from "mdi-react/TrashIcon";
import PlusIcon from "mdi-react/PlusIcon";
import AccountIcon from "mdi-react/AccountIcon";
import EmailIcon from "mdi-react/EmailIcon";

const AssembleiaForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [showModalNovoDocumento, setShowModalNovoDocumento] = useState(false);
  const [showModalParticipantes, setShowModalParticipantes] = useState(false);
  const [showModalMensagem, setShowModalMensagem] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const totalPages = useSelector((state) => state.assembleias.totalPages);
  const totalItems = useSelector((state) => state.assembleias.totalItems);
  const assembleia = useSelector((state) => state.assembleias.assembleia);
  const documentos = useSelector(
    (state) => state.assembleias.assembleia.documentos
  );
  const participantes = useSelector(
    (state) => state.assembleias.assembleia.participantes
  );
  const chapas = useSelector((state) => state.chapas.chapas);
  const isFetching = useSelector((state) => state.assembleias.isFetching);
  const isUpdating = useSelector((state) => state.assembleias.isUpdating);
  const error = useSelector((state) => state.assembleias.error);
  const TextArea = renderComponentField("textarea");
  const formRef = useRef({});
  const { id } = props.match.params;
  const isAddMode = !id;

  useEffect(() => {
    if (isUpdating) return;
    if (!isAddMode) {
      dispatch(getAssembleia(id));
    } else {
      dispatch(newAssembleia());
    }
  }, [dispatch, id, isAddMode, isUpdating]);

  useEffect(() => {
    dispatch(fetchChapas(1, 9999));
  }, [dispatch]);

  if (assembleia) {
    assembleia.dataAberturaDatePicker = moment(
      assembleia.dataAbertura
    ).toDate();
    assembleia.dataEncerramentoDatePicker = moment(
      assembleia.dataEncerramento
    ).toDate();
    assembleia.statusSelect = {
      value: assembleia.status,
      label: assembleia.status,
    };
    assembleia.chapasSelect = assembleia.chapas?.map((chapa) => ({
      value: chapa._id,
      label: chapa.nome,
    }));
  }

  const colunas = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "nome",
        disableGlobalFilter: true,
        width: 100,
      },
    ],
    []
  );

  const dadosTabela = (participantes) => {
    return participantes.map((participante) => ({
      nome: participante.associado.nome,
    }));
  };

  const onSubmit = (data) => {
    const formData = {};
    const fields = Object.keys(data).map((key, i) => key);
    fields.map((item) => (formData[item] = data[item]));
    const { chapasSelect } = data;
    formData.chapas = chapasSelect.map((chapaSelect) => chapaSelect.value);
    formData.dataAbertura = data.dataAberturaDatePicker;
    formData.dataEncerramento = data.dataEncerramentoDatePicker;
    formData.status = data.statusSelect.value;

    isAddMode
      ? dispatch(createAssembleia(formData))
      : dispatch(updateAssembleia(id, formData));
    setIsRedirect(true);
  };

  const onSubmitMensagem = (values) => {
    dispatch(createMensagem(values));
    toggleModalMensagem(false);
  };

  const handlePdf = (index) => () => {
    const documento = assembleia.documentos[index];
    const data = documento.conteudo.data.data;
    const titulo = documento.titulo;
    setCurrentPdf({ data, titulo });
    setModalOpen(true);
  };

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const toggleModalParticipantes = () => {
    setShowModalParticipantes((prev) => !prev);
  };

  const toggleModalMensagem = () => {
    setShowModalMensagem((prev) => !prev);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/assembleias" />;
  }

  return (
    <Row>
      <Col md={6}>
        <Card>
          <CardBody>
            <div className="card__title container-titulo-assembleia">
              <h4 className="bold-text">
                {isAddMode ? "Nova" : "Editar"} Assembléia
              </h4>
              <div>
                <button
                  className="icon-btn primary"
                  id="participantes"
                  type="button"
                  onClick={() => setShowModalParticipantes(true)}
                >
                  <AccountIcon />
                </button>
                <button
                  className="icon-btn primary"
                  id="mensagem"
                  type="button"
                  onClick={() => setShowModalMensagem(true)}
                >
                  <EmailIcon />
                </button>
              </div>
              <UncontrolledTooltip placement="left" target="participantes">
                Participantes da Assembleia
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="left" target="mensagem">
                Mensagem para os Participantes
              </UncontrolledTooltip>
            </div>
            {isFetching && <Spinner className="table-fetch-spinner" />}
            {(assembleia || isAddMode) && !isFetching && (
              <Form
                onSubmit={onSubmit}
                validate={validate}
                initialValues={isAddMode ? {} : assembleia}
              >
                {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                  <form
                    className={`form ${isHorizontal && "form--horizontal"}`}
                    onSubmit={handleSubmit}
                  >
                    <div className="form__form-group">
                      <span className="form__form-group-label">Nome</span>
                      <div className="form__form-group-field">
                        <Field
                          name="nome"
                          type="text"
                          component={FormField}
                          rules={{ required: "Campo Obrigatório" }}
                          isAboveError={isAboveError}
                          placeholder="Nome da assembleia"
                          maxLength="15"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Data da abertura
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="dataAberturaDatePicker"
                          dateFormat="dd/MM/yyyy"
                          component={renderDatepickerField}
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Data do encerramento
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="dataEncerramentoDatePicker"
                          dateFormat="dd/MM/yyyy"
                          component={renderDatepickerField}
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Chapas</span>
                      <div className="form__form-group-field">
                        <Field
                          name="chapasSelect"
                          component={renderMultiSelectField}
                          placeholder="Chapas participantes"
                          isAboveError={isAboveError}
                          options={chapas.map((chapa) => ({
                            value: chapa._id,
                            label: chapa.nome,
                          }))}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Situação</span>
                      <div className="form__form-group-field">
                        <Field
                          name="statusSelect"
                          component={renderSelectField}
                          placeholder="Situação da assembleia"
                          isAboveError={isAboveError}
                          options={listaSituacaoAssembleia}
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit">
                        Salvar
                      </Button>
                      <Button type="button" onClick={() => setIsRedirect(true)}>
                        Cancelar
                      </Button>
                    </ButtonToolbar>
                  </form>
                )}
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>

      {isAddMode ? (
        ""
      ) : (
        <Col md={6}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h4 className="bold-text">Documentos anexados</h4>
                <ButtonToolbar
                  className="list__btn-toolbar-top btn btn-primary list__btn-add"
                  onClick={() => {
                    setShowModalNovoDocumento(true);
                  }}
                >
                  <PlusIcon /> Novo Documento
                </ButtonToolbar>
              </div>
              {isFetching && <Spinner className="table-fetch-spinner" />}
              {documentos && !isFetching && (
                <Table className="table--bordered" responsive>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentos.map((documento, i) => {
                      return (
                        <>
                          <tr key={documento?.id}>
                            <td>{documento?.titulo}</td>
                            <td>
                              <button
                                className="icon-btn success"
                                id="editar"
                                type="button"
                                onClick={handlePdf(i)}
                              >
                                <EyeIcon />
                              </button>
                            </td>
                            <td>
                              <button
                                className="icon-btn danger"
                                id="excluir"
                                type="button"
                                onClick={() =>
                                  dispatch(deleteDocumento(id, documento._id))
                                }
                              >
                                <TrashIcon />
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </Col>
      )}
      <PdfModal
        open={modalOpen}
        toggle={toggleModal}
        data={currentPdf?.data}
        titulo={currentPdf?.titulo}
      />
      <DocumentoFormModal
        open={showModalNovoDocumento}
        toggleModal={setShowModalNovoDocumento}
        isAboveError={isAboveError}
        id={id}
      />
      <Modal
        isOpen={showModalParticipantes}
        className="modal-participantes theme-light"
      >
        <h4 className="bold-text pb-2">Associados convocados para a Assembléia</h4>
        {participantes && !isFetching && (
          <ReactTableBase
            key={"common"}
            columns={colunas}
            data={dadosTabela(participantes)}
            tableConfig={{
              isEditable: false,
              isResizable: false,
              isSortable: false,
              withDragAndDrop: false,
              withPagination: true,
              withSearchEngine: false,
              manualPageSize: [10, 20, 30, 40],
              placeholder: "Search by First name...",
            }}
            setPage={setPage}
            setPageSize={setPageSize}
            currentpage={page}
            pageSize={pageSize}
            totalPages={totalPages}
            totalItems={totalItems}
          />
        )}
        <ModalFooter className="modal-footer-participantes">
          <Button onClick={() => toggleModalParticipantes(false)}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModalMensagem}
        className="modal-participantes theme-light"
      >
        <Form
          onSubmit={onSubmitMensagem}
          validate={validateMensagem}
          initialValues={{
            assunto: "Convite para Assembleia",
            mensagem: "Você recebeu um convite para Assembleia",
          }}
        >
          {({ handleSubmit, form, form: {} }) => {
            formRef.current = form;
            return (
              <form
                className={`form ${isHorizontal && "form--horizontal"}`}
                onSubmit={handleSubmit}
              >
                <div className="form__form-group">
                  <span className="form__form-group-label">Assunto</span>
                  <div className="form__form-group-field">
                    <Field
                      name="assunto"
                      component={FormField}
                      type="string"
                      rules={{ required: "Campo Obrigatório" }}
                      isAboveError={isAboveError}
                      placeholder="Digite o Assunto"
                      maxLength="100"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Mensagem</span>
                  <div className="form__form-group-field">
                    <Field
                      name="mensagem"
                      type="text"
                      component={TextArea}
                      isAboveError={isAboveError}
                      placeholder="Digite a Mensagem"
                      maxLength="1024"
                    />
                  </div>
                </div>

                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Enviar
                  </Button>
                  <Button onClick={() => toggleModalMensagem(false)}>
                    Voltar
                  </Button>
                </ButtonToolbar>
              </form>
            );
          }}
        </Form>
      </Modal>
    </Row>
  );
};

AssembleiaForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

AssembleiaForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default AssembleiaForm;
