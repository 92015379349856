import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { BannersList } from './components/BannersList';

const Banners = ({ rtl }) => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Banners</h3>
          <h3 className="page-subhead subhead">
            Crie ou atualize os Banners da plataforma.
          </h3>
        </Col>
      </Row>
      <Row>
        <BannersList />
      </Row>
    </Container>
  );
};

export default Banners;
