import React from 'react';
import { Badge } from 'reactstrap';

const BadgeAssociadoColor = {
  pendente: 'secondary',
  ativo: 'success',
  inativo: 'danger',
  suspenso: 'warning',
};

const BadgeAssociado = ({ situacao }) => (
  <Badge color={BadgeAssociadoColor[situacao]}>{situacao}</Badge>
);

const BadgeSituacaoColor = {
  emitido: 'success',
  utilizado: 'warning',
  vencido: 'primary',
  cancelado: 'danger',
};

const BadgeSituacao = ({ status }) => (
  <Badge color={BadgeSituacaoColor[status]}>{status}</Badge>
);

const BadgeNoticiaColor = {
  rascunho: 'secondary',
  publicado: 'success',
  removido: 'danger',
};

const BadgeNoticia = ({ status }) => (
  <Badge color={BadgeNoticiaColor[status]}>{status}</Badge>
);

const BadgeParticipantesColor = {
  'Presente': 'success',
  'Ausente': 'danger',
};

const BadgeParticipante = ({ status }) => (
  <Badge color={BadgeParticipantesColor[status]}>{status}</Badge>
);

const BadgeBannerColor = {
  rascunho: 'secondary',
  publicado: 'success',
  removido: 'danger',
};

const BadgeBanner = ({ status }) => (
  <Badge color={BadgeBannerColor[status]}>{status}</Badge>
);

export { BadgeAssociado, BadgeSituacao, BadgeNoticia, BadgeParticipante, BadgeBanner };
