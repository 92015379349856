import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import CardAccountDetailsIcon from 'mdi-react/CardAccountDetailsIcon';
import NewspaperIcon from 'mdi-react/NewspaperIcon';
import ShieldAccountIcon from 'mdi-react/ShieldAccountIcon';
import { ProcessosList } from './components/ProcessosList';
import WidgetQuantidade from './components/WidgetQuantidade';
import { fetchSearchUsuarios } from '@/redux/actions/usuarioActions';
import { fetchSearchNoticias } from '@/redux/actions/noticiaActions';
import { getStatsAssociados } from '@/redux/actions/associadoActions';

const Dashboard = ({ rtl }) => {
  const dispatch = useDispatch();
  const usuarios = useSelector((state) => state.usuarios?.totalItems);
  const noticias = useSelector((state) => state.noticias?.totalItems);
  const statsAssociados = useSelector((state) => state.associados.stats);
  const isFetchingAssociados = useSelector(
    (state) => state.associados.isFetching
  );
  const isFetchingUsuarios = useSelector((state) => state.usuarios.isFetching);
  const isFetchingNoticias = useSelector((state) => state.noticias.isFetching);

  useEffect(() => {
    dispatch(fetchSearchUsuarios(parseInt(1), 1));
    dispatch(fetchSearchNoticias(parseInt(1), 1));
    dispatch(getStatsAssociados());
  }, [dispatch]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <Row>
        <WidgetQuantidade
          quantidade={statsAssociados.associadosPendentes}
          titulo="Solicitações pendentes"
          icone={AccountGroupIcon}
          isFetching={isFetchingAssociados}
        />
        <WidgetQuantidade
          quantidade={statsAssociados.associadosAtivos}
          titulo="Associados ativos"
          icone={CardAccountDetailsIcon}
          isFetching={isFetchingAssociados}
        />
        <WidgetQuantidade
          quantidade={usuarios}
          titulo="Administradores"
          icone={ShieldAccountIcon}
          isFetching={isFetchingUsuarios}
        />
        <WidgetQuantidade
          quantidade={noticias}
          titulo="Camapanhas"
          icone={NewspaperIcon}
          isFetching={isFetchingNoticias}
        />
      </Row>
      <Row>
        <ProcessosList />
      </Row>
    </Container>
  );
};

export default Dashboard;
