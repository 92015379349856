import { deleteRequest, get, post, put } from './base/index';
import { getUserId } from '../helpers';
import { API } from '../constants';

export default {
  getAssociado: (id) => get(`${API}/admin/associado/${id}/${getUserId()}`),
  fetchAssociados: (page, pageSize) =>
    get(
      `${API}/admin/associados/${getUserId()}?page=${page}&pageSize=${pageSize}`
    ),
  searchAssociados: (page, pageSize, pesquisa) =>
    post(
      `${API}/admin/associados/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      pesquisa
    ),
  deleteAssociado: (id) =>
    deleteRequest(`${API}/admin/associado/${id}/${getUserId()}`),
  createAssociado: (form) =>
    post(`${API}/admin/associado/create/${getUserId()}`, form),
  updateAssociado: (id, form) =>
    put(`${API}/admin/associado/${id}/${getUserId()}`, form),
  aprovarAssociado: (id) =>
    put(`${API}/admin/associado/aprovar/${id}/${getUserId()}`),
  reprovarAssociado: (id) =>
    put(`${API}/admin/associado/reprovar/${id}/${getUserId()}`),
  suspenderAssociado: (id) =>
    put(`${API}/admin/associado/suspender/${id}/${getUserId()}`),
  reativarAssociado: (id) =>
    put(`${API}/admin/associado/reativar/${id}/${getUserId()}`),
  getStatsAssociados: (id) =>
    get(`${API}/admin/stats/associado/${getUserId()}`),
};
