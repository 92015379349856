import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Button,
  ButtonToolbar,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { DadosAcesso } from './components/DadosDeAcesso/DadosAcesso';
import { DadosPessoais } from './components/DadosPessoais/DadosPessoais';
import Dependentes from './components/Dependentes/Dependentes';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { regexFiltarNaN } from '../../utils/helpers';
import {
  getAssociado,
  newAssociadoRequest,
  createAssociado,
  updateAssociado,
  aprovarAssociado,
  reprovarAssociado,
  suspenderAssociado,
  reativarAssociado,
} from '../../redux/actions/associadoActions';
import { BadgeAssociado } from '../../shared/components/Badges';
import moment from 'moment/moment';

const Associado = (props) => {
  const { id } = props.match.params;
  const isAddMode = !id;
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const usuario = useSelector((state) => state.auth?.usuario);
  const associado = useSelector((state) => state.associados.associado);
  const isFetching = useSelector((state) => state.associados.isFetching);
  const isUpdating = useSelector((state) => state.associados.isUpdating);
  const error = useSelector((state) => state.associados.error);
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getAssociado(id));
    } else {
      dispatch(newAssociadoRequest());
    }
  }, [dispatch]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      dataNascimento: values?.dataNascimento,
      estadoCivil: (values.estadoCivil = values.estadoCivil?.value),
      empresaVinculo: (values.empresaVinculo = values.empresaVinculo?.value),
      cep: values.cep?.replace(regexFiltarNaN, ''),
      cpf: values.cpf?.replace(regexFiltarNaN, ''),
      telCelular: values.telCelular?.replace(regexFiltarNaN, ''),
      telComercial: values.telComercial?.replace(regexFiltarNaN, ''),
      dependentes: values.dependentes?.map((dependente) => ({
        nome: dependente.nome,
        sexo: dependente.sexo,
        dataNascimento: dependente.dataNascimento,
        cpf: dependente.cpf?.replace(regexFiltarNaN, ''),
      })),
    };
    if (isAddMode) {
      data.password = 'temporarypassword123456';
    }
    isAddMode
      ? dispatch(createAssociado(data))
      : dispatch(updateAssociado(id, data));
    setIsRedirect(true);
  };

  const handleAprovarAssociado = () => {
    dispatch(aprovarAssociado(id));
    setIsRedirect(true);
  };

  const handleReprovarAssociado = () => {
    dispatch(reprovarAssociado(id));
    setIsRedirect(true);
  };

  const handleSuspenderAssociado = () => {
    dispatch(suspenderAssociado(id));
    setIsRedirect(true);
  };

  const handleReativarAssociado = () => {
    dispatch(reativarAssociado(id));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !isUpdating && !error) {
    if (isAddMode)
      return <Redirect to={`/associado/editar/${associado._id}`} />;
    else return <Redirect to="/associados" />;
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            {isAddMode ? 'Novo' : 'Editar'} Associado
          </h3>
        </Col>
      </Row>
      {associado && !isAddMode && !isFetching && !isUpdating && (
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={3}>
                    Situação cadastral:{' '}
                    <BadgeAssociado situacao={associado?.situacao} />
                  </Col>
                  <Col lg={4}>
                    {`Cadastrado em ${moment(associado?.createdAt).format(
                      'DD/MM/YYYY HH:mm'
                    )}`}
                  </Col>
                  <Col lg={5}>
                    <ButtonToolbar className="list__btn-toolbar-top">
                      {!associado?.aprovacao?.find(
                        (aprov) =>
                          aprov.perfil === usuario.perfil && aprov.dataHora
                      ) && (
                        <>
                          <Button
                            color="primary"
                            className="list__btn-add"
                            onClick={() => handleAprovarAssociado()}
                          >
                            Aprovar
                          </Button>
                          <Button
                            color="danger"
                            className="list__btn-add"
                            onClick={() => handleReprovarAssociado()}
                          >
                            Reprovar
                          </Button>
                        </>
                      )}
                      {associado?.situacao === 'ativo' && (
                        <Button
                          color="secondary"
                          className="list__btn-add"
                          onClick={() => handleSuspenderAssociado()}
                        >
                          Suspender
                        </Button>
                      )}
                      {associado?.situacao === 'suspenso' && (
                        <Button
                          color="secondary"
                          className="list__btn-add"
                          onClick={() => handleReativarAssociado()}
                        >
                          Reativar
                        </Button>
                      )}
                    </ButtonToolbar>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--justify tabs--bordered-bottom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggle('1')}
                      >
                        Dados Pessoais
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                        style={{ display: isAddMode ? 'none' : '' }}
                      >
                        Dependentes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggle('3')}
                        style={{ display: isAddMode ? 'none' : '' }}
                      >
                        Dados de Acesso
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <DadosPessoais
                        handleSubmit={onSubmit}
                        isHorizontal={false}
                        isAboveError
                        props={props}
                        associado={associado}
                        handleIsRedirect={setIsRedirect}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Dependentes
                        handleSubmit={onSubmit}
                        isHorizontal={false}
                        isAboveError
                        props={props}
                        associado={associado}
                        handleIsRedirect={setIsRedirect}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <DadosAcesso
                        handleSubmit={onSubmit}
                        isHorizontal={false}
                        isAboveError
                        props={props}
                        associado={associado}
                        handleIsRedirect={setIsRedirect}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Associado;
