import { handleActions } from 'redux-actions';

import {
  getVoucherRequest,
  getVoucherSuccess,
  getVoucherError,
  fetchVouchersRequest,
  fetchVouchersSuccess,
  fetchVouchersError,
  fetchSearchVouchersRequest,
  fetchSearchVouchersSuccess,
  fetchSearchVouchersError,
  createVoucherRequest,
  createVoucherSuccess,
  createVoucherError,
  updateVoucherRequest,
  updateVoucherSuccess,
  updateVoucherError,
  deleteVoucherRequest,
  deleteVoucherSuccess,
  deleteVoucherError,
  newVoucherRequest,
} from '../actions/voucherActions';

const defaultState = {
  vouchers: [],
  totalPages: 0,
  voucher: {},
  isFetching: false,
  isUpdating: false,
  error: null,
};

export default handleActions(
  {
    [getVoucherRequest](state) {
      return {
        ...state,
        voucher: {},
        isFetching: true,
        error: null,
      };
    },
    [getVoucherSuccess](state, { payload }) {
      return {
        ...state,
        voucher: payload,
        isFetching: false,
        error: null,
      };
    },
    [getVoucherError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchVouchersRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchVouchersSuccess](state, { payload }) {
      return {
        ...state,
        vouchers: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchVouchersError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchVouchersRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchVouchersSuccess](state, { payload }) {
      return {
        ...state,
        vouchers: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchSearchVouchersError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createVoucherRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [createVoucherSuccess](state, { payload }) {
      return {
        ...state,
        voucher: payload,
        isUpdating: false,
        error: null,
      };
    },
    [createVoucherError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [updateVoucherRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [updateVoucherSuccess](state, { payload }) {
      return {
        ...state,
        voucher: payload,
        isUpdating: false,
        error: null,
      };
    },
    [updateVoucherError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [deleteVoucherRequest](state) {
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    },
    [deleteVoucherSuccess](state) {
      return {
        ...state,
        isUpdating: false,
        error: null,
      };
    },
    [deleteVoucherError](state, { payload }) {
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    },
    [newVoucherRequest](state, { payload }) {
      return {
        ...state,
        voucher: {},
        isFetching: false,
        isUpdating: false,
        error: payload,
      };
    },
  },
  defaultState
);
