export const validate = (values, isAddMode) => {
  console.log(isAddMode)
  const errors = {};
  if (!values.nome) {
    errors.nome = 'O campo Nome não pode estar vazio';
  }
  if (!values.telCelular) {
    errors.telCelular = 'O campo Telefone não pode estar vazio';
  }
  if (!values.password && isAddMode) {
    errors.password = 'O campo Senha não pode estar vazio';
  } else if (values.password && (values.password.length < 6 || values.password.length > 12)) {
    errors.password = 'A senha deve possuir entre 6 a 12 caracteres';
  }
  if (!values.perfil) {
    errors.perfil = 'O campo Perfil não pode estar vazio';
  }
  return errors;
};
