import { JWT_KEY } from './constants';
import { regexNaN, regexMaskTelefone } from './constants';

export * from './constants';

export const getToken = () => {
  const usuario = JSON.parse(localStorage.getItem(JWT_KEY));

  if (usuario) {
    return usuario.token;
  } else {
    return '';
  }
};

export const getUserId = () => {
  const { usuario } = JSON.parse(localStorage.getItem(JWT_KEY));
  if (usuario) {
    return usuario._id;
  } else {
    return '';
  }
};

export const telefoneMask = (value) => {
  const cleaned = value?.replace(regexNaN, '');
  const match = cleaned?.match(regexMaskTelefone)?.groups;

  if (!match) return cleaned;

  for (let i in match) {
    if (!match[i]) {
      match[i] = '';
    }
  }
  if (match.long) return match.long.substr(0, 11);

  if (match.ddd && !match.first) return match.ddd;

  if (match.last) return `(${match.ddd}) ${match.first}-${match.last}`;

  if (match.first) return `(${match.ddd}) ${match.first}`;

  return cleaned;
};

export const formatFields = (fields, formRef) => {
  const { values } = formRef.current.getState();
  for (let field of fields) {
    const { name, mask } = field;
    values[name] && formRef.current.change(name, mask(values[name]));
  }
};
export const maskCEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const regexFiltarNaN = /[\D]+/g;

export const formatarCpf = (value) => {
  return value
    ?.replace(regexNaN, '')
    ?.replace(/(\d{3})(\d)/, '$1.$2')
    ?.replace(/(\d{3})(\d)/, '$1.$2')
    ?.replace(/(\d{3})(\d)/, '$1-$2')
    ?.replace(/(-\d{2})\d+?$/, '$1');
};

export const arrayToBase64 = (value, options = {}) => {
  const {type} = options;
  let array = value;
  let result = array.reduce(
    (data, byte) => data + String.fromCharCode(byte),
    ''
  );
  return `data:${type || 'image/png'};base64,` + btoa(result);
};

export const bufferToBase64 = (value, { type }) =>
  new Promise((resolve) => {
    var blob = new Blob([value], { type });

    var reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    reader.readAsDataURL(blob);
  });

export const listaSituacaoAssembleia = [
  { value: 'ativo', label: 'ativo' },
  { value: 'inativo', label: 'inativo' },
];

export const listaCategoria = [
  { value: 'Comer & Beber', label: 'comer & beber' },
  { value: 'Cultura & Lazer', label: 'cultura & lazer' },
  { value: 'Turismo', label: 'turismo' },
  { value: 'Cursos', label: 'cursos' },
  { value: 'Saúde e Esportes', label: 'saúde e esportes' },
  { value: 'Lojas', label: 'lojas' },
  { value: 'Profissionais', label: 'profissionais' },
  { value: 'Farmácias', label: 'farmácias' },
  { value: 'Beleza & Bem-estar', label: 'beleza & bem-estar' },
  { value: 'Serviços', label: 'serviços' },
];

export const listaSituacao = [
  { value: 'ativo', label: 'ativo' },
  { value: 'inativo', label: 'inativo' },
  { value: 'pendente', label: 'pendente' },
];

export const listaSituacaoNoticia = [
  { value: 'rascunho', label: 'rascunho' },
  { value: 'publicado', label: 'publicado' },
  { value: 'removido', label: 'removido' },
];

export const listaSituacaoBanner = [
  { value: 'rascunho', label: 'rascunho' },
  { value: 'publicado', label: 'publicado' },
  { value: 'removido', label: 'removido' },
];
