export const validate = (values) => {
  const errors = {};
  if (!values.assunto) {
    errors.assunto = 'O campo Assunto não pode estar vazio';
  }
  if (!values.mensagem) {
    errors.mensagem = 'O campo Mensagem não pode estar vazio';
  }

  return errors;
};
