import { createAction } from 'redux-actions';
import { displayError, displaySuccess } from './notificacoesActions';
import chapasApi from '../../utils/api/chapaApi';

export const getChapaRequest = createAction('GET_CHAPA_REQUEST');
export const getChapaSuccess = createAction('GET_CHAPA_SUCCESS');
export const getChapaError = createAction('GET_CHAPA_ERROR');

export const fetchChapasRequest = createAction('FETCH_CHAPAS_REQUEST');
export const fetchChapasSuccess = createAction('FETCH_CHAPAS_SUCCESS');
export const fetchChapasError = createAction('FETCH_CHAPAS_ERROR');

export const createChapaRequest = createAction('CREATE_CHAPA_REQUEST');
export const createChapaSuccess = createAction('CREATE_CHAPA_SUCCESS');
export const createChapaError = createAction('CREATE_CHAPA_ERROR');

export const updateChapaRequest = createAction('UPDATE_CHAPA_REQUEST');
export const updateChapaSuccess = createAction('UPDATE_CHAPA_SUCCESS');
export const updateChapaError = createAction('UPDATE_CHAPA_ERROR');

export const deleteChapaRequest = createAction('DELETE_CHAPA_REQUEST');
export const deleteChapaSuccess = createAction('DELETE_CHAPA_SUCCESS');
export const deleteChapaError = createAction('DELETE_CHAPA_ERROR');

export const fetchSearchChapasRequest = createAction(
  'FETCH_SEARCH_CHAPAS_REQUEST'
);
export const fetchSearchChapasSuccess = createAction(
  'FETCH_SEARCH_CHAPAS_SUCCESS'
);
export const fetchSearchChapasError = createAction('FETCH_SEARCH_CHAPAS_ERROR');

export const newChapaRequest = createAction('NEW_CHAPA_REQUEST');

export const getChapa = (id) => async (dispatch) => {
  try {
    dispatch(getChapaRequest());
    const { data } = await chapasApi.readChapa(id);
    dispatch(getChapaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getChapaError(e));
    dispatch(displayError(e));
  }
};

export const fetchChapas = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchChapasRequest());
    const { data } = await chapasApi.listChapas(page, pageSize);
    dispatch(fetchChapasSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchChapasError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchChapas =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchChapasRequest());
      const { data } = await chapasApi.searchChapas(page, pageSize, search);
      dispatch(fetchSearchChapasSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchSearchChapasError(e));
      dispatch(displayError(e));
    }
  };

export const createChapa = (form) => async (dispatch) => {
  try {
    dispatch(createChapaRequest());
    const { data } = await chapasApi.createChapa(form);
    dispatch(displaySuccess('Usuário incluído com sucesso!'));
    dispatch(createChapaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(createChapaError(e));
    dispatch(displayError(e));
  }
};

export const updateChapa = (id, form) => async (dispatch) => {
  try {
    dispatch(updateChapaRequest());
    const { data } = await chapasApi.updateChapa(id, form);
    dispatch(displaySuccess('Chapa atualizado com sucesso'));
    dispatch(updateChapaSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(updateChapaError(e));
    dispatch(displayError(e));
  }
};

export const deleteChapa = (id) => async (dispatch) => {
  try {
    dispatch(deleteChapaRequest());
    const { data } = await chapasApi.deleteChapa(id);
    dispatch(deleteChapaSuccess(data.message));
    dispatch(displaySuccess(data.message));
  } catch (e) {
    console.log(e);
    dispatch(deleteChapaError(e));
    dispatch(displayError(e));
  }
};

export const newChapa = () => async (dispatch) => {
  dispatch(newChapaRequest());
};
